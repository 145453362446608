import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import gold from "../../images/gold.svg";
import platinum from "../../images/platinum.svg";

import {
  fetchLga,
  postLga,
  postDeleteLga,
  postEditLga,
} from "../../queries/lga";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import swal from "sweetalert";

const Lga = (props) => {
  const [form, setForm] = useState({
    title: "",
    content: "",
    message: "",
    id: "",
    error: false,
  });
  const [page, setPage] = useState("lga");

  const [mutate, { isLoading }] = useMutation(postLga);
  const [mutateEdit, { isLoading: editLoad }] = useMutation(postEditLga);
  const [mutateDelete, { isLoading: deleteLoad }] = useMutation(postDeleteLga);
  const { status, data, refetch } = useQuery("lga", fetchLga);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openLga, setOpenLga] = React.useState(false);
  const [lgaBody, setLgaBody] = React.useState({});

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPostLga = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data: newLga, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newLga.message });
        refetch();
        setOpen(false);
        setForm({ ...form, id: "", name: "", about: "" });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onPostEditLga = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEdit({ form });
      const { data: newLga, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newLga.message });
        refetch();
        setOpenEdit(false);
        setForm({ ...form, id: "", name: "", about: "" });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onPostDeleteLga = async (id) => {
    try {
      const res = await mutateDelete(id);
      const { data: deleteLga, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: deleteLga.message });
        refetch();
        setOpen(false);
        setForm({ ...form, id: "", name: "", about: "" });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onPostDeleteLga(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onView = (lg) => {
    setLgaBody(lg);
    // console.log(lgaBody)
    console.log(lg.name);
    setOpenLga(true);
    handleClose();
  };

  const onEdit = (id) => {
    setForm({ ...form, id: id.id, name: id.name, about: id.about });
    setOpenEdit(true);
    handleClose();
  };

  const [file, setFile] = useState();
  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  return (
    <>
      <MainLayout>
        <div className="lga">
          {/* <section className="lga-nav">
            <CardBox>
              <div className="row">
                <div className="col-md-2">
                  <span
                    onClick={() => {
                      setPage("lga");
                    }}
                    className={page === "lga" ? "active-btn" : ""}
                  >
                    Lga
                  </span>
                </div>
                <div className="col-md-10 text-right">
                  <i class="fa fa-filter" aria-hidden="true"></i>
                </div>
              </div>
            </CardBox>
          </section> */}

          <p className="page-title">LGA</p>
          <section className="create">
            <CardBox>
              <DialogBox
                open={open}
                close={() => setOpen(false)}
                buttonTitle={
                  <span onClick={() => setOpen(true)}>
                    <i
                      className="fa fa-plus-square-o fa-lg icon-create"
                      aria-hidden="true"
                    ></i>
                    create LGA
                  </span>
                }
                buttonOk={
                  <button className="btn btn-dark btn-sm" onClick={onPostLga}>
                    {isLoading && (
                      <Spinner animation="border" className="ml-2" size="sm" />
                    )}{" "}
                    add LGA
                  </button>
                }
              >
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}
                  {/* <div className="avatar-cont">
                    <img id="output" width="150px" height="150px" />
                  </div>
                  <div className="form-group">
                    <input
                      id="output"
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                      multiple
                      required
                    />
                  </div> */}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="local government title"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="description"
                      className="form-control form-control-sm"
                      id="desc"
                      cols="30"
                      rows="5"
                      placeholder="description"
                      value={form.about}
                      onChange={(e) =>
                        setForm({ ...form, about: e.target.value })
                      }
                    ></textarea>
                  </div>
                </form>
              </DialogBox>
            </CardBox>
          </section>

          <section className="mt-3 lga-table row">
            <div className="col-md-12">
              <CardBox>
                <p>Top LGA</p>

                <Table responsive hover size="md">
                  <thead className="lga-table-head">
                    <tr>
                      <th>LGA</th>
                      <th>DESCRIPTION</th>
                      <th>NO OF INFRACTION</th>
                      <th>DATE</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.LGAs.map((lg, i) => {
                      return (
                        <tr key={i}>
                          <td>{lg.name}</td>
                          <td>{truncate(lg.about, 35)}</td>
                          <td>{lg.number_of_infractions}</td>
                          <td>{dayjs(lg.createdAt).format("MMM DD")}</td>
                          <td>
                            <button className="btn btn-sm btn-info" onClick={() => onView(lg)}>
                             <small>view</small>
                            </button>
                          </td>
                          <td>
                            <button className="btn btn-sm btn-secondary" onClick={() => onEdit(lg)}>
                             <small>edit</small>
                            </button>
                            </td>
                          <td>
                            <button className="btn btn-sm btn-danger" onClick={() => handleDelete(lg.id)}>
                            <small>delete</small>
                            </button>
                          </td>
                          {/* <td>
                            <i
                              className="fa fa-ellipsis-h icon-menu"
                              aria-hidden="true"
                              onClick={handleClick}
                            ></i>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={() => onView(lg)}>
                                <small className="text-info">view</small>
                              </MenuItem>
                              <MenuItem onClick={() => onEdit(lg)}>
                                <small className="text-secondary">edit</small>
                              </MenuItem>
                              <MenuItem onClick={() => onDelete(lg.id)}>
                                <small className="text-danger">delete</small>
                              </MenuItem>
                            </Menu>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {status === "loading" && (
                  <div className="text-center p-5">
                    <Spinner
                      animation="border"
                      className="ml-2 text-center text-success"
                      size="lg"
                    />
                  </div>
                )}
              </CardBox>
            </div>
            {/* <div className="col-md-4">
              <CardBox>
                <p>Quick details</p>

                <Table responsive hover size="md">
                  <tbody>
                    {data?.categories.map((category, i) => {
                      return (
                        <tr key={i}>
                          <td> ✅ {category.name}</td>
                          <td>{category.number_ofof_infractions}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBox>
            </div> */}
          </section>

          <DialogBox
            open={openLga}
            close={() => setOpenLga(false)}
            buttonOk={
              <button
                className="btn btn-dark btn-sm"
                onClick={() => setOpenLga(false)}
              >
                ok
              </button>
            }
          >
            <div>
              <h5>{lgaBody.name}</h5>
              <p>{lgaBody.about}</p>
            </div>
          </DialogBox>

          <DialogBox
            open={openEdit}
            close={() => setOpenEdit(false)}
            buttonOk={
              <button className="btn btn-dark btn-sm" onClick={onPostEditLga}>
                {editLoad && (
                  <Spinner animation="border" className="ml-2" size="sm" />
                )}{" "}
                edit LGA
              </button>
            }
          >
            <form>
              {form.error && (
                <small className="text-danger mb-4">{form.message}</small>
              )}
              {!form.error && (
                <small className="text-success mb-2">{form.message}</small>
              )}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="exampleFormControlInput1"
                  placeholder="local government title"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  className="form-control form-control-sm"
                  id="desc"
                  cols="30"
                  rows="5"
                  placeholder="description"
                  value={form.about}
                  onChange={(e) => setForm({ ...form, about: e.target.value })}
                ></textarea>
              </div>
            </form>
          </DialogBox>
        </div>
      </MainLayout>
    </>
  );
};

export default Lga;
