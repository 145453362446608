import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";
import BackButton from "../../components/BackButton/BackButton";
import { NotificationContainer, NotificationManager } from "react-notifications";

import {
  fetchUserInfractionActivity,
  fetchSingleUser,
  postUpdateSuspendStatus,
} from "../../queries/user";
import { useQuery, useMutation, usePaginatedQuery } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import { Auth } from "../../config";
import swal from "sweetalert";

import { fetchAgency } from "../../queries/agency";
import { LoadScript } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";

import CardInfraction from "../../components/CardInfraction/CardInfraction";
import ViewMore from "../../components/ViewMore/ViewMore";
const UserProfile = (props) => {
  const history = useHistory();
  const {
    match: { params },
  } = props;
  const [pageCount, setPageCount] = useState(12);
  const { data, refetch } = useQuery(["user_profile", params.user], fetchSingleUser);
  const { isLoading, resolvedData, isError, error, status } = usePaginatedQuery(
    ["infraction_activity", params.user, pageCount],
    fetchUserInfractionActivity
  );
  const [mutateStatusUpdate, { isLoading: statusUpdateLoading }] = useMutation(
    postUpdateSuspendStatus
  );

  const viewMore = () => {
    setPageCount(pageCount + 6);
  };

  const updateStatusHandler = async () => {
    try {
      const { id, is_suspended } = data.user;
      const res = await mutateStatusUpdate({ id, is_suspended: !is_suspended });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        NotificationManager.error("Failed", errors.message);
      } else {
        NotificationManager.success("Success", message);
        refetch();
      }
    } catch (error) {
      NotificationManager.error("Failed", error);
    }
  };

  return (
    <MainLayout>
      <NotificationContainer />
      <BackButton back={() => history.goBack()} />
      {data && (
        <div className="user-profile">
          <section className="user-info">
            <CardBox>
              <div className="row">
                <div className="col-md-4 profile-photo">
                  <img src={data?.user.profile_picture} alt="profile" className="image-avatar" />
                </div>
                <div className="col-md-8 info">
                  <section>
                    <p>
                      {data?.user.first_name} {data?.user.last_name}
                      {"  "}
                      <small>
                        {data?.user.is_suspended ? (
                          <span className="badge badge-warning">suspended</span>
                        ) : (
                          <span className="badge badge-success">active</span>
                        )}
                      </small>
                    </p>
                    <p>{data?.user.phone}</p>
                    <p>{data?.user.email}</p>
                    <small>
                      Joined on {dayjs(data?.user.createdAt).format("MMMM D, YYYY h:mm A")}
                    </small>
                    <section className="mt-3">
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={() => updateStatusHandler()}
                      >
                        {data?.user.is_suspended ? <small>restore</small> : <small>suspend</small>}
                      </button>
                    </section>
                  </section>
                </div>
              </div>
            </CardBox>
          </section>
          {status === "loading" && (
            <div className="text-center p-5">
              <Spinner animation="border" className="ml-2 text-center text-success" size="lg" />
            </div>
          )}
          {resolvedData?.infractions.length < 1 ? (
            <div className="text-center p-5">
              <h6>{data?.user.first_name} has not reported any infraction</h6>
            </div>
          ) : (
            <section className="infraction-activity mt-2">
              <CardBox>
                <div className="text-center">
                  <small>{data?.user.first_name}'s infraction activity</small>
                </div>
              </CardBox>
              <div className="row mt-2">
                {resolvedData?.infractions.map((infraction, i) => {
                  return (
                    <div className="col-md-4 mb-4" key={i}>
                      <CardInfraction
                        status={infraction.status}
                        navigate={() => history.push(`/infraction/${infraction.id}`)}
                        infraction={infraction}
                      />
                    </div>
                  );
                })}
              </div>
            </section>
          )}
          <ViewMore viewHandler={viewMore} />
        </div>
      )}
    </MainLayout>
  );
};

export default UserProfile;
