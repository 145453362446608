import React, { useState, useEffect } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import BackButton from "../../components/BackButton/BackButton";
// import { APIKey } from "../../config/index";
import extractStatus from "../../helpers/infraction-status";
import ViewMore from "../../components/ViewMore/ViewMore";

import { fetchInfraction, updateInfraction, assignInfraction, fetchInfractionLogs, postComment } from "../../queries/infractions";
import { queryCache, useMutation, useQuery, usePaginatedQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import CardInfraction from "../../components/CardInfraction/CardInfraction";
import split_location from "../../helpers/split-address";
import truncate from "../../helpers/truncate";
import dayjs from "dayjs";
import MyMapComponent from "../../components/Maps/Maps";
import swal from "sweetalert";
import { fetchAgency } from "../../queries/agency";
import { useHistory } from "react-router-dom";

import ImageGallery from "react-image-gallery";

const Infraction = (props) => {
  const history = useHistory();
  const {
    match: { params },
  } = props;
  const [pageCount, setPageCount] = useState(8);
  const { isLoading, data, isError, error, refetch } = useQuery(["infraction", params.infraction], fetchInfraction);

  const { isLoading: agencyLoad, data: agencyData } = useQuery("agencies", fetchAgency);
  const { resolvedData, latestData, status, refetch: refetchInfractionLogs } = usePaginatedQuery(["infraction_logs", params.infraction, pageCount], fetchInfractionLogs);
  const [mutate, { isLoading: updateInfra }] = useMutation(updateInfraction);
  const [mutateAssign, { isLoading: assignLoading }] = useMutation(assignInfraction);
  const [mutateComment, { isLoading: commentLoading }] = useMutation(postComment);

  const [form, setForm] = useState({
    id: params.infraction,
    status: "",
    agency: "",
    message: "",
    error: false,
  });

  const [comment, setComment] = useState({
    id: params.infraction,
    text: "",
    error: false,
    message: "",
  });

  const [fullscreen, setFullscreen] = useState(false);
  let statusColor = {
    waiting: "#D8D8D8",
    ongoing: "#007bff",
    completed: "#4ABA4C",
    "on hold": "#ffc402",
    due: "#CF0A1E",
  };

  const [carouselImages, setCarouselImages] = useState([]);

  useEffect(() => {
    if (data) {
      let images = data.infraction?.media_url
        ?.filter((media) => !checkIsVideo(media))
        .map((url) => {
          return {
            original: url,
          };
        });
      setCarouselImages(images);
    }
  }, [data]);
  const onUpdateInfraction = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      console.log(res);
      const { data: newInfraction, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newInfraction.message });
        refetch(); //queryCache.invalidateQueries("infraction");
        swal("Status updated", message, "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onAssignInfraction = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateAssign({ form });
      console.log(res);
      const { data: newInfraction, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newInfraction.message });
        refetch(); //queryCache.invalidateQueries("infraction");
        swal("Agency assigned", message, "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const viewMore = () => {
    setPageCount(pageCount + 4);
  };

  const onComment = async (e) => {
    e.preventDefault();
    try {
      if (comment.text.trim().length > 0) {
        const res = await mutateComment({ comment });
        const { hasError, errors, message } = res.data;
        if (hasError) {
          console.log(errors.message);
          setComment({ ...comment, error: hasError, message: errors.message });
        } else {
          setComment({ ...comment, error: hasError, message: message, text: "" });
          refetchInfractionLogs(); //queryCache.invalidateQueries("infraction");
        }
      } else {
        setComment({ ...comment, error: true, message: "No comment to post" });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const viewUserHandler = () => {
    if (data.infraction.user) history.push(`/user-activity/${data.infraction.user.id}`);
  };

  const checkIsVideo = (url) => {
    const isVideo = /(.WEBM|.MPG|.MP2|.MPEG|.MPE|.MPV|.OGG|.MP4|.M4P|.M4V|.AVI|.WMV|.MOV|.QT|.FLV|.SWF|.AVCHD)$/i.test(url);
    return isVideo;
  };

  const handlerFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  return (
    <>
      <MainLayout>
        <BackButton back={() => history.goBack()} />
        {data?.infraction && (
          <div className="single-infraction">
            {/* {isLoading && (
            <div className="text-center p-5">
              <Spinner
                animation="border"
                className="ml-2 text-center text-success"
                size="lg"
              />
            </div>
          )} */}
            {fullscreen && (
              <div>
                <small className="close-fullscreen" onClick={() => handlerFullscreen()}>
                  close <i class="fa fa-remove" aria-hidden="true"></i>
                </small>
                <ImageGallery items={carouselImages} />
              </div>
            )}
            <section className={fullscreen ? "single-infraction disabled" : "single-infraction"}>
              {data.infraction.media_url && (
                <div className="infraction-medias">
                  <p className="medias-title">Infraction media:</p>
                  <div className="row">
                    {data.infraction.media_url.map((url) => (
                      <div className="col-md-6 mb-4">
                        <div className="col-md-12 infra-media-container">
                          {checkIsVideo(url) ? (
                            <video className="infra-media" controls>
                              <source src={url} type="video/mp4" />
                              <source src={url} type="video/ogg" />
                              <source src={url} type="video/webm" />
                              Sorry - Your browser does not support the HTML5 video tag.
                            </video>
                          ) : (
                            <img src={url} alt="pic" className="infra-media clickable-image" onClick={() => handlerFullscreen(url)} />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="row  mt-4">
                <div className="col-md-6">
                  <div className="info-infra">
                    <p className="info-title">{split_location(data.infraction.location)}</p>
                    <small className="infra-location">{truncate(data.infraction.location, 40)}</small>
                    <p className="info-text">{data.infraction.summary}</p>
                  </div>
                  <div className="maps-container img-infra">
                    <MyMapComponent isMarkerShown latitude={data.infraction.latitude} longitude={data.infraction.longitude} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="infra-text-date">
                    <table className="table table-hover table-responsive table-borderless table-lg">
                      <tbody>
                        <tr>
                          <td>Code</td>
                          <td>{data.infraction.code}</td>
                        </tr>
                        <tr>
                          <td>Reported on</td>
                          <td> {dayjs(data.infraction.createdAt).format("MMMM D, YYYY h:mm A")}</td>
                        </tr>
                        <tr>
                          <td>Estimated time of completion </td>
                          <td> {dayjs(data.infraction.estoc).format("MMMM D, YYYY h:mm A")}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            {" "}
                            <span
                              style={{
                                color: statusColor[extractStatus(data.infraction)],
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {extractStatus(data.infraction)}
                            </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>Local Government</td>
                          <td>{data.infraction?.lga?.name}</td>
                        </tr>
                        <tr>
                          <td>Market</td>
                          <td>{data.infraction?.market?.name}</td>
                        </tr>
                        <tr>
                          <td>Assigned</td>
                          <td>{data.infraction.assigned_agency ? data.infraction.assignedagencies.map((agenc) => <span className="pr-2">{agenc.agency.acronym}, </span>) : "Not assigned"}</td>
                        </tr>
                        <tr>
                          <td>Reported by</td>
                          <section className="reported-by" onClick={() => viewUserHandler()}>
                            <td>{data.infraction.user ? data.infraction.user.first_name + " " + data.infraction.user.last_name : "anonymous"}</td>
                          </section>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="info-infra">
                    {data?.infraction.assigned && (
                      <form>
                        {form.error && <small className="text-danger mb-4">{form.message}</small>}
                        {!form.error && <small className="text-success mb-2">{form.message}</small>}
                        <div className="input-group mb-3">
                          <select className="custom-select form-control-sm" id="inputGroupSelect01" value={form.status} onChange={(e) => setForm({ ...form, status: e.target.value })}>
                            <option selected>select status</option>
                            <option>waiting</option>
                            <option>ongoing</option>
                            <option>on hold</option>
                            <option>due</option>
                            <option>completed</option>
                          </select>
                        </div>
                        <button className="btn btn-dark float-right btn-sm mb-2 ml-2" onClick={onUpdateInfraction}>
                          {updateInfra && <Spinner animation="border" className="ml-2" size="sm" />} update status
                        </button>
                      </form>
                    )}

                    <form>
                      <div className="input-group mb-3">
                        <select className="custom-select form-control-sm" id="inputGroupSelect01" value={form.agency} onChange={(e) => setForm({ ...form, agency: e.target.value })}>
                          <option selected>select agency</option>
                          {agencyData?.agencies.map((agency, i) => {
                            return (
                              <option value={agency.id} key={i}>
                                {agency.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <input type="date" className="form-control " id="exampleFormControlInput1" placeholder="Start date" value={form.estoc} onChange={(e) => setForm({ ...form, estoc: e.target.value })} />
                      </div>
                      <button className="btn btn-info float-right btn-sm" onClick={onAssignInfraction}>
                        {" "}
                        {assignLoading && <Spinner animation="border" className="ml-2" size="sm" />} assign
                      </button>
                    </form>
                    <br />
                  </div>
                </div>
              </div>
              <div className="infraction-logs mt-3 row">
                <div className="infraction-comment mt-3 col-md-6">
                  <form className="form-group">
                    {comment.error && <small className="text-danger mb-4">{comment.message}</small>}
                    {!comment.error && <small className="text-success mb-2">{comment.message}</small>}
                    <div className="input-group mb-3">
                      <textarea className="form-control form-control-sm" id="exampleCommentControlInput1" placeholder="Post comment..." value={comment.text} onChange={(e) => setComment({ ...comment, text: e.target.value })} />
                    </div>
                    <button className="btn btn-success float-right btn-sm mb-2 ml-2" onClick={onComment}>
                      {" "}
                      {commentLoading && <Spinner animation="border" className="ml-2" size="sm" />} <small>Comment</small>
                    </button>
                  </form>
                </div>
                <div className="infraction-comment mt-3 col-md-6">
                  <p>Activity Log:</p>
                  {resolvedData?.infraction_logs.length > 0 ? (
                    <>
                      {resolvedData?.infraction_logs.map((infraction_log, i) => (
                        <div className="infraction-log-item">
                          <div className="log-meta">
                            <small>{dayjs(infraction_log.createdAt).format("MMMM D, YYYY h:mm A")}</small>
                          </div>
                          <div className="log-description">
                            <p>{infraction_log.description}</p>
                          </div>
                        </div>
                      ))}
                      <ViewMore viewHandler={viewMore} />
                    </>
                  ) : (
                    <p>no logs to show.</p>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
      </MainLayout>
    </>
  );
};

export default Infraction;

{
  /* <div className="fullscreen">
<div className="fullscreen-image">
  <small className="close-fullscreen" onClick={() => handlerFullscreen()}>
    close <i class="fa fa-remove" aria-hidden="true"></i>
  </small>
  <img src={fullscreen} alt="full" />
</div>
</div> */
}
