import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";

export const postLogin = async ({ form }) => {
  const data = {
    email: form.email,
    password: form.password,
  };
  const res = await Axios.post(API_CONSTANT_MAP.login, data);
  return res;
};

export const forgotPassword = async ({ form }) => {
  const res = await Axios.get(`${API_CONSTANT_MAP.forgotPassword}${form.email}`);
  return res;
};

export const resetPassword = async ({ form }) => {
  const data = {
    email: form.email,
    // password: form.password,
    // oldPassword: form.oldPassword,
    new_password: form.password,
    token: form.token,
  };
  const res = await Axios.put(API_CONSTANT_MAP.resetPassword, data);
  return res;
};

export const changePassword = async ({ form }) => {
  const data = {
    oldPassword: form.oldPassword,
    newPassword: form.newPassword,
  };
  const res = await Axios.post(API_CONSTANT_MAP.changePassword, data);
  return res;
};

export const postCreateUser = async ({ form }) => {
  const data = {
    email: form.email,
    first_name: form.firstname,
    last_name: form.lastname,
    phone: form.phone,
    role: form.role,
    agency_id: form.agency_id,
  };
  const res = await Axios.post(API_CONSTANT_MAP.admin.create, data);
  return res;
};

export const postUpdateUser = async ({ form }) => {
  const data = {
    email: form.email,
    role: form.role,
    agency_id: form.agency_id,
  };
  const res = await Axios.put(API_CONSTANT_MAP.admin.update, data);
  return res;
};

export const postUpdateSuspendStatus = async ({ id, is_suspended }) => {
  const res = await Axios.put(`${API_CONSTANT_MAP.admin.suspend_user}${id}/${is_suspended}`);
  return res;
};
export const fetchAllUser = async (key, count) => {
  const res = await Axios.get(`${API_CONSTANT_MAP.admin.users}&pages=1&paginate=${count || 12}`);

  return res.data.data;
};

export const fetchSingleUser = async (key, params) => {
  const res = await Axios.get(API_CONSTANT_MAP.admin.single_user + params);

  return res.data.data;
};

export const fetchAdminUser = async (key, count) => {
  const res = await Axios.get(`${API_CONSTANT_MAP.admin.admin}&pages=1&paginate=${count || 12}`);

  return res.data.data;
};

export const fetchUserInfractionActivity = async (key, params, count) => {
  const res = await Axios.get(
    `${API_CONSTANT_MAP.admin.user_infraction_activities}${params}?pages=1&paginate=${count || 12}`
  );

  return res.data.data;
};

export const searchUsers = async (key, query, count) => {
  if (query.length > 0) {
    const res = await Axios.get(
      `${API_CONSTANT_MAP.admin.search_user}${query.trim()}&pages=1&paginate=${count || 12}`
    );

    return res.data.data;
  }
};

export const fetchMetrics = async () => {
  const res = await Axios.get(`${API_CONSTANT_MAP.admin.metrics}`);
  return res.data.data;
};

export const fetchGraph = async (key, duration, category_id) => {
  const res = await Axios.get(
    `${API_CONSTANT_MAP.admin.graph}${category_id || 17}?frequency=${duration || "yearly"}`
  );
  let category = res.data.data.plot.map((period) => period.period);
  if (duration === "monthly")
    category = res.data.data.plot.map((period) => "WEEK " + period.period);
  if (duration === "daily") category = res.data.data.plot.map((period) => period.period + ":00");

  //return res.data.data;
  return {
    series: res.data.data.plot.map((count) => count.count),
    category,
  };
};
