const BASE_API = "https://sandbox.citimonitor.com/api/v1";
// const BASE_API = "http://localhost:7000/api/v1";

const USER_API = `${BASE_API}/users`;
const MEDIA_API = `${BASE_API}/media`;
const INFRACTION_API = `${BASE_API}/admin/infraction`;
const INFRACTION_API_AGENCY = `${BASE_API}/admin/agency/infraction`;
const CATEGORY_API = `${BASE_API}/category`;
const AGENCY_API = `${BASE_API}/agency`;
const AGENCY_ADMIN_API = `${BASE_API}/admin/agency`;
const NEWS_API = `${BASE_API}/info`;
const NEWS_ADMIN_API = `${BASE_API}/admin/news`;
const NOTIFICATION_API = `${BASE_API}/notification`;
const NOTIFICATION_ADMIN_API = `${BASE_API}/admin/notification`;
const EVENT_API = `${BASE_API}/event`;
const PARTNER_API = `${BASE_API}/partner`;
const PARTNER_ADMIN_API = `${BASE_API}/admin/partner`;
const PIONEER_PARTNER_API = `${BASE_API}/pioneer-partner`;
const PIONEER_PARTNER_ADMIN_API = `${BASE_API}/admin/pioneer-partner`;
const PIONEER_PARTNER_PRODUCT_API = `${BASE_API}/pioneer-product`;
const PIONEER_PARTNER_PRODUCT_ADMIN_API = `${BASE_API}/admin/pioneer-product`;
const WEATHER_API = `${BASE_API}/weather`;
const FAQ_API = `${BASE_API}/faq`;
const FAQ_ADMIN_API = `${BASE_API}/admin/faq`;
const LGA_API = `${BASE_API}/lga`;
const ADVERT_API = `${BASE_API}/advert`;
const ADMIN_API = `${BASE_API}/admin`;
const MARKET_API = `${BASE_API}/market`;

const API_CONSTANT_MAP = {
  //user
  login: `${USER_API}/login/`,
  forgotPassword: `${USER_API}/forgot-password/`,
  changePassword: `${USER_API}/change-password/`,
  resetPassword: `${USER_API}/reset-password/`,

  //infraction
  infractions: {
    view: `${INFRACTION_API}?status=`,
    update: `${INFRACTION_API}/status`,
    assign: `${INFRACTION_API}/assign`,
    updateAgency: `${INFRACTION_API_AGENCY}/process`,
    viewByCoordinates: `${ADMIN_API}/infraction-coordinates?status=`,
  },

  //categories
  categories: `${CATEGORY_API}`,

  //agencies
  agency: `${AGENCY_API}`,
  agencyAdmin: `${AGENCY_ADMIN_API}`,

  //news
  news: `${NEWS_API}`,
  newsAdmin: `${NEWS_ADMIN_API}`,

  //event
  event: `${EVENT_API}`,

  //partner
  partner: {
    fetchAll: `${PARTNER_API}?category=`,
    create: `${PARTNER_API}`,
    delete: `${PARTNER_ADMIN_API}/`,
    edit: `${PARTNER_ADMIN_API}/`,
  },

  //pioneer partner
  pioneer_partner: {
    fetchAll: `${PIONEER_PARTNER_API}?category=`,
    create: `${PIONEER_PARTNER_API}`,
    delete: `${PIONEER_PARTNER_ADMIN_API}/`,
    edit: `${PIONEER_PARTNER_ADMIN_API}/`,
  },

  //pioneer partner product
  pioneer_partner_product: {
    fetchAll: `${PIONEER_PARTNER_PRODUCT_API}?category=`,
    create: `${PIONEER_PARTNER_PRODUCT_API}`,
    delete: `${PIONEER_PARTNER_PRODUCT_ADMIN_API}/`,
    edit: `${PIONEER_PARTNER_PRODUCT_ADMIN_API}/`,
  },

  //weather
  weather: `${WEATHER_API}`,

  //notification
  notification: {
    fetchAll: `${NOTIFICATION_API}`,
    create: `${NOTIFICATION_API}`,
    delete: `${NOTIFICATION_ADMIN_API}/`,
  },

  //faq
  faq: `${FAQ_API}`,
  faqAdmin: `${FAQ_ADMIN_API}`,

  //lga
  lga: `${LGA_API}`,

  //advert
  advert: `${ADVERT_API}`,

  //infraction
  admin: {
    users: `${ADMIN_API}/users/all?admin=false`,
    admin: `${ADMIN_API}/users/all?admin=true`,
    create: `${ADMIN_API}/createuser`,
    assign: `${ADMIN_API}/search`,
    search_user: `${BASE_API}/users/admin/search?search=`,
    single_user: `${BASE_API}/users/admin/user-profile/`,
    suspend_user: `${ADMIN_API}/user/suspend/`,
    user_infraction_activities: `${ADMIN_API}/infractions/user-activity/`,
    single: `${ADMIN_API}/infraction/single`,
    update: `${ADMIN_API}/user/role`,
    metrics: `${ADMIN_API}/metrics`,
    graph: `${ADMIN_API}/graph/`,
    audit: `${ADMIN_API}/logs/all?`,
    audit_settings: `${ADMIN_API}/logs/settings/`,
    suspension_settings: `${ADMIN_API}/suspension/settings/`,
    delete_infraction: `${ADMIN_API}/infraction/`,
  },

  agency_infraction: `${BASE_API}/infractions/admin/agency`,
  infraction_search: `${BASE_API}/infractions/admin/search`,
  infraction_logs: `${BASE_API}/infractions/admin/logs`,
  comment: `${BASE_API}/infractions/admin/comment`,

  //market
  market: `${MARKET_API}`,
};

export default API_CONSTANT_MAP;
