import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import { fetchFaq, postFaq } from "../../queries/faq";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import swal from "sweetalert";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { fetchEvents, postEvent } from "../../queries/events";
const localizer = momentLocalizer(moment);

const daee = new Date("2020-09-22T00:00:00.000Z");

const Events = (props) => {
  const [form, setForm] = useState({
    message: "",
    error: false,
  });

  const [open, setOpen] = React.useState(false);
  const [mutate, { isLoading }] = useMutation(postEvent);
  const { status, data, refetch } = useQuery("events", fetchEvents);
  const [eventList, setEventList] = useState([
    {
      title: 'string',
      start: new Date("2020-09-22T00:00:00.000Z"),
      end: new Date("2020-09-22T00:00:00.000Z"),
    },
  ]);

  const onPostEvent = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newCat.message });
        setOpen(false);
        swal("Event created", message, "success");
        refetch();
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  return (
    <>
      <MainLayout>
        <section className="events">
          <h3>Events</h3>
          <section className="create pb-4">
            <CardBox>
              <DialogBox
                open={open}
                close={() => setOpen(false)}
                buttonTitle={
                  <span onClick={() => setOpen(true)}>
                    <i
                      className="fa fa-plus-square-o fa-lg icon-create"
                      aria-hidden="true"
                    ></i>
                    create event
                  </span>
                }
                buttonOk={
                  <button className="btn btn-dark btn-sm" onClick={onPostEvent}>
                    {isLoading && (
                      <Spinner animation="border" className="ml-2" size="sm" />
                    )}{" "}
                    add event
                  </button>
                }
              >
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="event name"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="start-date">Start date</label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="Start date"
                      value={form.startDate}
                      onChange={(e) =>
                        setForm({ ...form, startDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="End-date">End date</label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="End date"
                      
                      value={form.endDate}
                      onChange={(e) =>
                        setForm({ ...form, endDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="venue"
                      value={form.venue}
                      onChange={(e) =>
                        setForm({ ...form, venue: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="description"
                      className="form-control form-control-sm"
                      id="desc"
                      cols="30"
                      rows="5"
                      placeholder="description"
                      value={form.description}
                      onChange={(e) =>
                        setForm({ ...form, description: e.target.value })
                      }
                    ></textarea>
                  </div>
                </form>
              </DialogBox>
            </CardBox>
          </section>

          <CardBox>

            <Table responsive hover size="md">
              <Calendar
                localizer={localizer}
                events={data || []}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
              />

            </Table>
        
          </CardBox>
        </section>
      </MainLayout>
    </>
  );
};

export default Events;
