import React, { Fragment, useState } from "react";
import "./styles.scss";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import ROUTES from "../../routes";
import auth2 from "../../images/auth2.png";
import citi from "../../images/citi.svg";
import { useMutation } from "react-query";
import { resetPassword } from "../../queries/user";
import { NotificationContainer, NotificationManager } from "react-notifications";

const ResetPassword = (props) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const tokenReset = searchParams.get("token");

  const [mutate, { isLoading }] = useMutation(resetPassword);
  const [form, setForm] = useState({
    email: email,
    password: "",
    passwordConfirm: "",
    token: tokenReset,
    message: "",
    error: false,
  });

  const onPostReset = async (e) => {
    e.preventDefault();
    if (form.password !== form.passwordConfirm) {
      setForm({
        ...form,
        password: "",
        passwordConfirm: "",
        error: true,
        message: "Passwords must match",
      });
      NotificationManager.error("Failed", "Passwords must match");
      return;
    }
    try {
      const res = await mutate({ form });
      const { data, hasError, errors, message } = res.data;
      console.log(res.data);
      if (hasError) {
        setForm({
          ...form,
          password: "",
          passwordConfirm: "",
          error: hasError,
          message: errors.message,
        });
        NotificationManager.error("Failed", errors.message);
      } else {
        NotificationManager.success("Success", "Password has been reset");
        setForm({ ...form, password: "", message: message });
        setTimeout(() => {
          if (data.updatedUser.user_role === "user" || data.updatedUser.user_role === "staff") {
            history.push("/mobile-redirect");
          } else {
            history.push("/");
          }
        }, 1200);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
      setForm({
        password: "",
        passwordConfirm: "",
      });
    }
  };

  return (
    <Fragment>
      <NotificationContainer />
      <main>
        <section className="page-image">
          <div className="page-image-welcome">
            <p className="welcome-text">Welcome to Citi Monitor Think green, think clean, think Lagos.</p>
          </div>
        </section>
        <section className="page-form">
          <div className="page-form-container">
            <div className="page-form-fields">
              <img src={citi} alt="citi" width="70%" />
              <p className="auth-text mt-3">Hello! Please create a new password to access your account.</p>
              <Form onSubmit={onPostReset}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" placeholder={email} className="auth-input" disabled value={form.email} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control type="password" placeholder="Create password" className="auth-input" value={form.password} onChange={(e) => setForm({ ...form, password: e.target.value })} required autoComplete="new-password" />
                </Form.Group>
                <Form.Group controlId="formBasicPassword2">
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    className="auth-input"
                    value={form.passwordConfirm}
                    onChange={(e) => setForm({ ...form, passwordConfirm: e.target.value })}
                    required
                    autoComplete="new-password"
                  />
                </Form.Group>
                <div className="form-remember">
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" className="" />
                  </Form.Group>
                  <Link to={ROUTES.FORGOT_PASSWORD} className="">
                    Not you?
                  </Link>
                </div>
                <Button variant="success" className="auth-button" type="submit">
                  Create new password {isLoading && <Spinner animation="border" className="" size="sm" />}
                </Button>
              </Form>
            </div>
          </div>
          <div className="page-form-image-footer">
            <p className="auth-text">Powered by</p>
            <img src={auth2} width="70%" alt="lag" />
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default ResetPassword;
