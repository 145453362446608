import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";
import catflag from "../../images/catflag.svg"

import {
  fetchCategory,
  postCategory,
  deleteCategory,
} from "../../queries/category";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import swal from "sweetalert";

const Categories = (props) => {
  const [form, setForm] = useState({
    name: "",
    about: "",
    message: "",
    error: false,
  });
  const [mutate, { isLoading }] = useMutation(postCategory);
  const [mutateDelete] = useMutation(deleteCategory);

  const { status, data, refetch } = useQuery("categories", fetchCategory);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPostCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newCat.message });
        refetch();
        setOpen(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onDeleteCategory = async (id) => {
    try {
      const res = await mutateDelete(id);
      const { data: deleteCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: deleteCat.message });
        refetch();
        setOpen(false);
        handleClose();
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteCategory(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  return (
    <>
      <MainLayout>
        <div className="categories">
          <p className="page-title">Categories</p>
          <section className="create">
            <CardBox>
              <DialogBox
                open={open}
                close={() => setOpen(false)}
                buttonTitle={
                  <span onClick={() => setOpen(true)}>
                    <i
                      className="fa fa-plus-square-o fa-lg icon-create"
                      aria-hidden="true"
                    ></i>
                    create category
                  </span>
                }
                buttonOk={
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={onPostCategory}
                  >
                    {isLoading && (
                      <Spinner animation="border" className="ml-2" size="sm" />
                    )}{" "}
                    add category
                  </button>
                }
              >
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="category title"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="description"
                      className="form-control form-control-sm"
                      id="desc"
                      cols="30"
                      rows="5"
                      placeholder="description"
                      value={form.about}
                      onChange={(e) =>
                        setForm({ ...form, about: e.target.value })
                      }
                    ></textarea>
                  </div>
                </form>
              </DialogBox>
            </CardBox>
          </section>

          <section className="mt-3 cat-table row">
            <div className="col-md-8">
              <CardBox>
                <p>Top categories</p>

                <Table responsive hover size="md">
                  <thead className="cat-table-head">
                    <tr>
                      <th>CATEGORY</th>
                      <th>DESCRIPTION</th>
                      <th>NO OF INFRACTION</th>
                      <th>DATE</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.categories.map((category, i) => {
                      return (
                        <tr key={i}>
                          <td>{category.name}</td>
                          <td>{category.about}</td>
                          <td>{category.number_of_infractions}</td>
                          <td>
                            {dayjs(category.createdAt).format("MMM DD")}
                          </td>

                          <td>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDelete(category.id)}
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {status === "loading" && (
                  <div className="text-center p-5">
                    <Spinner
                      animation="border"
                      className="ml-2 text-center text-success"
                      size="lg"
                    />
                  </div>
                )}
              </CardBox>
            </div>
            <div className="col-md-4 mt-3">
              <CardBox>
                <p>Quick details</p>

                <Table responsive hover size="md">
                  <tbody>
                    {data?.categories.map((category, i) => {
                      return (
                        <tr key={i}>
                          <td> <img src={catflag}  /> {category.name}</td>
                          <td>{category.number_ofof_infractions}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBox>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Categories;
