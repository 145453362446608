import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";

export default function NavBar(props) {
  const { toggle, logout, user } = props;
  const history = useHistory();
  const [search, setSearch] = useState({ name: "" });

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom nav-cont">
        <i className="fa fa-bars toggle-menu" aria-hidden="true" onClick={toggle}></i>

        <div className="nav-item search-all">
          <input type="text" name="search" className="ml-5  search-input" id="" placeholder="search infrations by code, lga, or description" onChange={(e) => setSearch({ ...search, name: e.target.value })} />
          <a href={`/search?q=${search.name}`}>
            <i className="fa fa-search search-icon" aria-hidden="true"></i>
          </a>
        </div>

        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="fa fa-bell" aria-hidden="true"></i>
            </a>
          </li>
          <li className="nav-item dropdown d-flex">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {user?.first_name}
            </a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="#">
                Role: {user?.user_role}
              </a>
              <a className="dropdown-item" href="#">
                Agency: {user?.agency?.acronym}
              </a>
              <a className="dropdown-item" href="#">
                Feedback
              </a>
              <div className="dropdown-divider"></div>
              <span className="dropdown-item" onClick={logout}>
                Logout
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}
