import { Auth } from "../config";

const extractStatus = (infraction) => {
  try {
    const { agency } = Auth.getUser();
    let acronym = agency ? agency.acronym : null;
    let status = infraction.status;
    if (acronym !== "MOEWR" && infraction.assigned) {
      const assignedAgency = infraction.assignedagencies.filter(
        (agency) => agency.agency.acronym === acronym
      );
      const reports = assignedAgency[0].agency.agencyreports.filter(
        (report) => report.infraction_id === infraction.id
      );

      if (reports.length > 0) {
        status = reports[reports.length - 1].infraction_status;
      } else {
        status = "waiting";
      }
    }
    return status;
  } catch (error) {
    console.log("error", error);
  }
};

export default extractStatus;
