import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const fetchLogs = async (key, filter, count) => {
  const res = await Axios.get(`${API_CONSTANT_MAP.admin.audit}pages=1&paginate=${count || 12}&filter=${filter || "all"}`);
 return res.data.data;
};

export const fetchSettings = async (key) => {
  const res = await Axios.get(`${API_CONSTANT_MAP.admin.audit_settings}`);
  console.log("fetchSettings", res.data.data);
  return res.data.data;
};

export const updateSettings = async ({ user_role, is_logged }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const data = {
    is_logged: is_logged,
  };
  const res = await Axios.put(`${API_CONSTANT_MAP.admin.audit_settings}${user_role || "user"}`, data, {
    headers,
  });
  return res.data.data;
};
