import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";
import { Spinner } from "react-bootstrap";

import { fetchInfractionsByCoordinates } from "../../queries/infractions";
import { useQuery } from "react-query";
import InfractionsMapComponent from "../../components/Maps/MultipleMarkersMap";

import { getThreshold } from "../../helpers/map-threshold";

const InfractionsMap = () => {
  const [coordinates, setCoordinates] = useState({
    longitude1: 3.356834554648951,
    longitude2: 3.357172848260954,
    latitude1: 6.50921583289734,
    latitude2: 6.509349078805428,
  });
  const [center, setCenter] = useState({
    lat: 6.509282455855798,
    lng: 3.3570037014549525,
  });
  const [zoom, setZoom] = useState(22);
  const [page, setPage] = useState("all");
  const { data, isLoading } = useQuery(["infractions_by_coords", page, coordinates], fetchInfractionsByCoordinates);

  const viewMapMeta = (map) => {
    const bounds = map.getBounds();
    const boundsKeys = Object.keys(bounds);
    const boundsObj = boundsKeys.reduce((acc, k) => ({ ...acc, [k]: bounds[k] }), {});
    const { [boundsKeys[0]]: Lats, [boundsKeys[1]]: Longs } = boundsObj;
    const latCenter = map.getCenter().lat();
    const lngCenter = map.getCenter().lng();
    const currentZoom = map.getZoom();
    console.log("boundsObj, bounds", boundsObj, bounds);
    /* 
    ? use longitude and latitude center change rates and set center accordingly
    ? another thing to consider is the non-linear relationship between zoom level and viewPort change
    ? also the zoom rate
    */
    const latChangeRate = Math.abs(((center.lat - latCenter) / latCenter) * 100) * currentZoom;
    const lngChangeRate = Math.abs(((center.lng - lngCenter) / lngCenter) * 100) * currentZoom;
    const zoomChange = zoom - currentZoom;

    const threshold = getThreshold(zoom, latChangeRate, lngChangeRate);
    if (zoomChange > 0) {
      handleViewPortChange(Lats, Longs, latCenter, lngCenter, currentZoom);
    } else if (threshold) {
      handleViewPortChange(Lats, Longs, latCenter, lngCenter, currentZoom);
    }
  };

  const handleViewPortChange = async (Lats, Longs, latCenter, lngCenter, currentZoom) => {
    try {
      const boundKeysLats = Object.keys(Lats);
      const boundKeysLongs = Object.keys(Longs);
      if (Lats && Longs)
        setCoordinates({
          latitude1: Lats[boundKeysLats[0]],
          latitude2: Lats[boundKeysLats[1]],
          longitude1: Longs[boundKeysLongs[0]],
          longitude2: Longs[boundKeysLongs[1]],
        });
      setCenter({
        lat: latCenter,
        lng: lngCenter,
      });
      setZoom(currentZoom);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainLayout>
      <div className="infractions-map">
        <section className="infrac-map-nav">
          <CardBox>
            <div className="nav-infrac-map">
              <div className="col-md-2">
                <span
                  onClick={() => {
                    setPage("all");
                  }}
                  className={page === "all" ? "active-btn" : ""}
                >
                  All{" "}
                </span>
              </div>
              <div className="col-md-2 ">
                <span
                  onClick={() => {
                    setPage("ongoing");
                  }}
                  className={page === "ongoing" ? "active-btn" : ""}
                >
                  Ongoing{" "}
                </span>
              </div>
              <div className="col-md-2">
                <span
                  onClick={() => {
                    setPage("on hold");
                  }}
                  className={page === "on hold" ? "active-btn" : ""}
                >
                  OnHold
                </span>
              </div>
              <div className="col-md-2">
                <span
                  onClick={() => {
                    setPage("waiting");
                  }}
                  className={page === "waiting" ? "active-btn" : ""}
                >
                  Waiting{" "}
                </span>
              </div>
              <div className="col-md-2">
                <span
                  onClick={() => {
                    setPage("completed");
                  }}
                  className={page === "completed" ? "active-btn" : ""}
                >
                  Completed{" "}
                </span>
              </div>
              <div className="col-md-1">
                <span
                  onClick={() => {
                    setPage("due");
                  }}
                  className={page === "due" ? "active-btn" : ""}
                >
                  Due
                </span>
              </div>
              <div className="col-md-1 text-right">
                <span>
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </CardBox>
        </section>
        <section className="mt-4">
          <CardBox>
            <div className="infractions-loading">
              {isLoading ? (
                <p>
                  <small>
                    <Spinner animation="border" className="ml-2" size="sm" /> Loading Infractions...
                  </small>
                </p>
              ) : (
                <p>
                  <small>
                    Infractions loaded <i className="fa fa-check" aria-hidden="true" />
                  </small>
                </p>
              )}
            </div>
            <div className="maps-container">
              <InfractionsMapComponent
                isMarkerShown
                // latitude={data?.infractions[0].latitude}
                // longitude={data?.infractions[0].longitude}
                infractions={data?.infractions}
                updateParent={(map) => viewMapMeta(map)}
                zoom={zoom}
              />
            </div>
          </CardBox>
        </section>
      </div>
    </MainLayout>
  );
};

export default InfractionsMap;
