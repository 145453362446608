import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import { fetchNews, postNews, deleteNews, postEditNews } from "../../queries/news";
import {
  fetchNotification,
  postNotification,
  deleteNotification,
} from "../../queries/notification";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import swal from "sweetalert";

const News = (props) => {
  const [form, setForm] = useState({
    title: "",
    content: "",
    messageInfo: "",
    error: false,
  });
  const [page, setPage] = useState("news");
  const [newsInfo, setNewsInfo] = useState({ title: "" });
  const [notifyInfo, setNotifyInfo] = useState({ title: "" });

  const [mutate, { isLoading }] = useMutation(postNews);
  const [mutateDeleteNews] = useMutation(deleteNews);
  const [mutateEditNews, { isLoading: editNewsLoad }] = useMutation(postEditNews);
  const [mutateNotify, { isLoading: loader }] = useMutation(postNotification);
  const [mutateDeleteNotification] = useMutation(deleteNotification);

  const { status, data, refetch } = useQuery("news", fetchNews);
  const { status: loads, data: allNotifications, refetch: callAgain } = useQuery(
    "notifications",
    fetchNotification
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openNotify, setOpenNotify] = React.useState(false);

  const [file, setFile] = useState("");
  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPostNews = async (e) => {
    e.preventDefault();
    if (file === "") {
      setForm({ ...form, error: true, messageInfo: "please upload an image" });
      return;
    }
    try {
      const res = await mutate({ form });
      const { hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, messageInfo: errors.message });
      } else {
        setForm({ ...form, error: hasError, messageInfo: message });
        refetch();
        setOpen(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onPostNotify = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateNotify({ form });
      const { data: newNotify, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, messageInfo: errors.message });
      } else {
        setForm({ ...form, error: hasError, messageInfo: newNotify.message });
        callAgain();
        setOpenNotify(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const viewNews = (news) => {
    setNewsInfo(news);
    handleClose();
  };

  const viewNotify = (notify) => {
    setNotifyInfo(notify);
    handleClose();
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteNews(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onDeleteNews = async (id) => {
    try {
      const res = await mutateDeleteNews(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message, "", "error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onEditNews = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEditNews({ form });
      console.log(res);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, title: "", news: "" });
        refetch();
        setOpenEdit(false);
        swal(message, "", "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const editNews = (news) => {
    setForm({
      ...form,
      title: news.title,
      news: news.news,
      id: news.id,
    });
    setOpenEdit(true);
  };

  
  const handleNotificationDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteNotification(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onDeleteNotification = async (id) => {
    try {
      const res = await mutateDeleteNotification(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message, "", "error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        callAgain();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  return (
    <>
      <MainLayout>
        <div className="news">
          <section className="infrac-nav">
            <CardBox>
              <div className="nav-infrac">
                <div className="col-md-2">
                  <span
                    onClick={() => {
                      setPage("news");
                    }}
                    className={page === "news" ? "active-btn" : ""}
                  >
                    News
                  </span>
                </div>
                <div className="col-md-2">
                  <span
                    onClick={() => {
                      setPage("notify");
                    }}
                    className={page === "notify" ? "active-btn" : ""}
                  >
                    Notification
                  </span>
                </div>
                <div className="col-md-8 text-right">
                  <i class="fa fa-filter" aria-hidden="true"></i>
                </div>
              </div>
            </CardBox>
          </section>

          {page === "news" && (
            <section className="mt-3 news-table">
              <CardBox>
                <p>Top news</p>

                <div className="info-cont d-flex row">
                  <div className="info-list col-md-6">
                    <Table responsive hover size="md">
                      <tbody>
                        {data?.news.map((news, i) => {
                          return (
                            <tr>
                              <td colspan="4" onClick={() => viewNews(news)}>
                                {truncate(news.title, 30)}
                              </td>
                              <td>{dayjs(news.createdAt).format("MMM D")}</td>
                              <td>
                                <button
                                  onClick={() => editNews(news)}
                                  className="btn btn-sm btn-secondary"
                                >
                                  <small>edit</small>
                                </button>
                              </td>
                              <td>
                                <button
                                  onClick={() => handleDelete(news.id)}
                                  className="btn btn-sm btn-danger"
                                >
                                  <small>delete</small>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <div className="info-details col-md-6">
                    {newsInfo.title === "" ? (
                      <h4 className="text-center p-5">Select a News</h4>
                    ) : (
                      <>
                        <img src={newsInfo.image} width="100%" height="250px" alt="" />
                        <h5 className="news-title">{newsInfo.title}</h5>
                        <hr />
                        <p>{newsInfo.news}</p>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <DialogBox
                    open={open}
                    close={() => setOpen(false)}
                    buttonTitle={
                      <button className="btn btn-success" onClick={() => setOpen(true)}>
                        New
                      </button>
                    }
                    buttonOk={
                      <button className="btn btn-dark btn-sm" onClick={onPostNews}>
                        {isLoading && <Spinner animation="border" className="ml-2" size="sm" />} add
                        news
                      </button>
                    }
                  >
                    <form>
                      {form.error && <small className="text-danger mb-4">{form.messageInfo}</small>}
                      {!form.error && (
                        <small className="text-success mb-2">{form.messageInfo}</small>
                      )}
                      <div className="avatar-cont">
                        <img id="output" width="150px" height="150px" />
                      </div>
                      <div className="form-group">
                        <input
                          id="output"
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="news title"
                          value={form.title}
                          onChange={(e) => setForm({ ...form, title: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="description"
                          className="form-control form-control-sm"
                          id="desc"
                          cols="30"
                          rows="5"
                          placeholder="content"
                          value={form.news}
                          onChange={(e) => setForm({ ...form, news: e.target.value })}
                        ></textarea>
                      </div>
                    </form>
                  </DialogBox>

                  <DialogBox
                    open={openEdit}
                    close={() => setOpenEdit(false)}
                    buttonOk={
                      <button className="btn btn-dark btn-sm" onClick={onEditNews}>
                        {editNewsLoad && <Spinner animation="border" className="ml-2" size="sm" />}{" "}
                        edit news
                      </button>
                    }
                  >
                    <form>
                      {form.error && <small className="text-danger mb-4">{form.messageInfo}</small>}
                      {!form.error && (
                        <small className="text-success mb-2">{form.messageInfo}</small>
                      )}
                      {/* <div className="avatar-cont">
                    <img id="output" width="150px" height="150px" />
                  </div>
                  <div className="form-group">
                    <input
                      id="output"
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                      multiple
                      required
                    />
                  </div> */}
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="news title"
                          value={form.title}
                          onChange={(e) => setForm({ ...form, title: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="description"
                          className="form-control form-control-sm"
                          id="desc"
                          cols="30"
                          rows="5"
                          placeholder="content"
                          value={form.news}
                          onChange={(e) => setForm({ ...form, news: e.target.value })}
                        ></textarea>
                      </div>
                    </form>
                  </DialogBox>
                </div>
              </CardBox>
            </section>
          )}

          {page === "notify" && (
            <section className="mt-3 news-table">
              <CardBox>
                <p>Notifications</p>

                <div className="info-cont d-flex row">
                  <div className="info-list col-md-6">
                    <Table responsive hover size="md">
                      <tbody>
                        {allNotifications?.notifications.map((noti, i) => {
                          return (
                            <tr key={i}>
                              <td colSpan="4" onClick={() => viewNotify(noti)}>
                                {truncate(noti.title, 30)}
                              </td>
                              <td>{dayjs(noti.createdAt).format("MMM D")}</td>
                              <td>
                                <button
                                  onClick={() => handleNotificationDelete(noti.id)}
                                  className="btn btn-sm btn-danger"
                                >
                                  <small>delete</small>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <div className="info-details col-md-6">
                    {notifyInfo.title === "" ? (
                      <h4 className="text-center p-5">Select a Notification</h4>
                    ) : (
                      <>
                        <h5 className="news-title">{notifyInfo.title}</h5>
                        <p>{dayjs(notifyInfo.createdAt).format("MMMM DD")}</p>
                        <hr />
                        <p>{notifyInfo.message}</p>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <DialogBox
                    open={openNotify}
                    close={() => setOpenNotify(false)}
                    buttonTitle={
                      <button className="btn btn-success" onClick={() => setOpenNotify(true)}>
                        New
                      </button>
                    }
                    buttonOk={
                      <button className="btn btn-dark btn-sm" onClick={onPostNotify}>
                        {loader && <Spinner animation="border" className="ml-2" size="sm" />} add
                        notification
                      </button>
                    }
                  >
                    <form>
                      {form.error && <small className="text-danger mb-4">{form.messageInfo}</small>}
                      {!form.error && (
                        <small className="text-success mb-2">{form.messageInfo}</small>
                      )}
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="title"
                          value={form.title}
                          onChange={(e) => setForm({ ...form, title: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control form-control-sm"
                          id="desc"
                          cols="30"
                          rows="5"
                          placeholder="content"
                          value={form.message}
                          onChange={(e) => setForm({ ...form, message: e.target.value })}
                        ></textarea>
                      </div>
                      <div className="form-group mb-3">
                        <select
                          className="custom-select form-control form-control-sm "
                          id="inputGroupSelect01"
                          value={form.topic}
                          onChange={(e) => setForm({ ...form, topic: e.target.value })}
                        >
                          <option selected>select topic</option>
                          <option value="all">All</option>
                          <option value="android">Android</option>
                          <option value="ios">IOS</option>
                        </select>
                      </div>
                    </form>
                  </DialogBox>
                </div>
              </CardBox>
            </section>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default News;
