import React, { useEffect, useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";
import Chart from "react-apexcharts";
import { Auth } from "../../config";
import { Spinner } from "react-bootstrap";

import bar1 from "../../images/bar1.svg";
import bar2 from "../../images/bar2.svg";
import bar3 from "../../images/bar3.svg";
import path from "../../images/path.svg";
import weather from "../../images/weather.svg";
import { useQuery } from "react-query";
import { fetchMetrics, fetchGraph, fetchAllUser } from "../../queries/user";
import { fetchWeather } from "../../queries/weather";
import { fetchLga } from "../../queries/lga";
import { fetchCategory } from "../../queries/category";
import dayjs from "dayjs";

const Dashboard = (props) => {
  const [user, setUser] = useState({});
  const [duration, setDuration] = useState("monthly");
  const [currentCategory, setCurrentCategory] = useState(17);
  const { status, data, refetch } = useQuery("metrics", fetchMetrics);
  const { data: weatherApi } = useQuery("weather", fetchWeather);
  const { data: lga } = useQuery("lga", fetchLga);
  const { data: categories } = useQuery("category", fetchCategory);
  const { data: allUsers } = useQuery("allUsers", fetchAllUser);

  const { data: graph, isLoading: graphLoading } = useQuery(
    ["graph", duration, currentCategory],
    fetchGraph
  );

  const [series, setSeries] = useState({
    name: "infraction",
    data: ["30", "40", "45", "50", "49", "60", "70"],
  });

  const [options, setOptions] = useState({
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: graph?.category, //['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURDAY', 'FRIDAY', 'SATURDAY'],
    },
  });
  useEffect(() => {
    setSeries({ ...series, data: graph?.series });
    setOptions({ ...options, xaxis: { categories: graph?.category } });
  }, [graph]);

  useEffect(() => {
    setUser(Auth.getUser());
  }, []);

  return (
    <>
      <MainLayout>
        <div className="dashboard">
          <p className="welcome-title">
            Hello {user?.first_name}, think green, think clean, think Lagos.
          </p>

          <section className="row justify-content-center">
            <div className="col-md-3 mb-2">
              <CardBox>
                <p>Total report</p>

                <div className="d-flex justify-content-between">
                  <div>
                    <span className="data-count">{data?.totalReport}</span>{" "}
                    <br />
                    <small className="chart-icon">
                      <i class="fa fa-arrow-down" aria-hidden="true"></i> 0
                    </small>
                  </div>
                  <img src={bar1} alt="" />
                </div>
              </CardBox>
            </div>
            <div className="col-md-3 mb-2">
              <CardBox>
                <p>Ongoing</p>

                <div className="d-flex justify-content-between">
                  <div>
                    <span className="data-count">{data?.ongoing}</span>
                    <br />
                    <small className="chart-icon-p">
                      <i className="fa fa-arrow-up" aria-hidden="true"></i> 0
                    </small>
                  </div>

                  <img src={bar2} alt="" />
                </div>
              </CardBox>
            </div>
            <div className="col-md-3 mb-2">
              <CardBox>
                <p>Completed</p>

                <div className="d-flex justify-content-between">
                  <div>
                    <span className="data-count">{data?.completed}</span> <br />
                    <small className="chart-icon">
                      <i class="fa fa-arrow-down" aria-hidden="true"></i> 0
                    </small>
                  </div>

                  <img src={bar3} alt="" />
                </div>
              </CardBox>
            </div>
            {user?.agency?.acronym === "MOEWR" && (
              <div className="col-md-3 mb-2">
                <CardBox style={{ height: "100%" }}>
                  <p>Total users</p>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="data-count">{allUsers?.total}</span>{" "}
                      <br />
                    </div>
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </div>
                </CardBox>
              </div>
            )}
          </section>
          <section className="row mt-4">
            <div className="col-md-8 mb-2">
              <CardBox>
                <div className="d-flex justify-content-between">
                  <div>
                    <div>
                      <p className="quick">
                        Infraction categories {"  "}
                        {graphLoading && (
                          <Spinner
                            animation="border"
                            className="ml-2"
                            size="sm"
                          />
                        )}
                      </p>
                    </div>
                    {categories && (
                      <select
                        className="category-select"
                        id="categoryGroupSelect01"
                        value={currentCategory}
                        onChange={(e) => setCurrentCategory(e.target.value)}
                      >
                        {categories?.categories.map((category, i) => (
                          <option key={i} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <select
                      className="custom-select form-control form-control-sm "
                      id="inputGroupSelect01"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    >
                      <option value="yearly">yearly</option>
                      <option value="monthly">monthly</option>
                      <option value="weekly">weekly</option>
                      <option value="daily">daily</option>
                    </select>
                  </div>
                </div>
                <Chart
                  options={options}
                  series={[
                    {
                      name: "infractions",
                      data: series.data || [],
                    },
                  ]}
                  type="line"
                  width="100%"
                  height={400}
                />
              </CardBox>
            </div>
            <div className="col-md-4 mb-2">
              <CardBox>
                <div className="d-flex justify-content-between">
                  <p className="quick">Quick details</p>
                  <p className="detail-all">
                    All details{" "}
                    <i class="fa fa-caret-down" aria-hidden="true"></i>{" "}
                  </p>
                </div>

                <table className="table table-hover table-responsive table-borderless table-lg dash-table">
                  <tbody>
                    <tr>
                      <td>
                        <i
                          className="fa fa fa-check-circle text-success fa-lg"
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td className="infra-fig">Last 24 hour infractions</td>
                      <td className="infra-fig mb-5">
                        {data?.last24Hours} complaints
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i
                          className="fa fa fa-check-circle text-warning fa-lg"
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td className="infra-fig">Pending infractions</td>
                      <td className="infra-fig">{data?.pending} complaints</td>
                    </tr>
                    <tr>
                      <td>
                        <i
                          className="fa fa fa-check-circle text-secondary fa-lg"
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td className="infra-fig">Waiting infractions</td>
                      <td className="infra-fig">{data?.waiting} complaints</td>
                    </tr>
                    <tr>
                      <td>
                        <i
                          className="fa fa fa-check-circle text-success fa-lg"
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td className="infra-fig">Completed infractions</td>
                      <td className="infra-fig">
                        {data?.completed} complaints
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i
                          className="fa fa fa-check-circle text-danger fa-lg"
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td className="infra-fig">Due infractions</td>
                      <td className="infra-fig">{data?.due} complaints</td>
                    </tr>
                  </tbody>
                </table>
              </CardBox>
            </div>
          </section>

          <section className="row mt-4">
            <div className="col-md-8 mb-2">
              <CardBox>
                <div className="d-flex justify-content-between">
                  <span className="quick">Infraction by Local government</span>
                  <small className="text-success">view full report</small>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-2 d-flex justify-content-center align-items-center">
                    <img src={path} width="100%" alt="" />
                  </div>
                  <div className="col-md-6 mb-2">
                    {lga?.LGAs.map((lg, i) => {
                      return (
                        <>
                          <div className="infra-fig d-flex justify-content-between">
                            <span>{lg.name}</span>
                            <span>{lg.number_of_infractions}</span>
                          </div>
                          <div
                            className="progress mt-2 mb-4"
                            style={{ height: "5px" }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "70%", backgroundColor: "green" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </CardBox>
            </div>
            <div className="col-md-4 mb-2">
              <CardBox>
                <div className="d-flex justify-content-between">
                  <span className="quick">Weather report</span>
                  <small className="text-success">Share weather report</small>
                </div>

                <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                  <img
                    src={`http://openweathermap.org/img/w/${weatherApi?.weather.weather.icon}.png`}
                    width="100px"
                    height="100px"
                    alt=""
                  />
                  <p className="quick mt-2">
                    {weatherApi?.weather.name},{" "}
                    {weatherApi?.weather.sys.country}
                  </p>
                  <small>{dayjs().format("MMMM D, YYYY h:mm A")}</small>
                </div>
                <hr />
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <p className="degree">
                    {weatherApi?.weather.main.temp.toFixed(1)}ºC
                  </p>
                  <p className="quick">
                    {weatherApi?.weather.weather.description}
                  </p>
                </div>
                <hr />
              </CardBox>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Dashboard;
