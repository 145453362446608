import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import {
  fetchAgency,
  postAgency,
  postEditAgency,
  deleteAgency,
} from "../../queries/agency";
import { fetchCategory } from "../../queries/category";
import { useQuery, useMutation, usePaginatedQuery } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import swal from "sweetalert";

const Agency = (props) => {
  const [form, setForm] = useState({
    name: "",
    about: "",
    web_url: "",
    acronym: "",
    category_ids: [],
    images: "",
    message: "",
    error: false,
  });
  const [file, setFile] = useState();
  const [agency, setAgency] = useState({});
  const [pageCount, setPageCount] = useState(12);
  const [mutate, { isLoading }] = useMutation(postAgency);
  const [mutateEdit, { isLoading: agencyEdit }] = useMutation(postEditAgency);
  const [mutateDelete] = useMutation(deleteAgency);
  const { resolvedData, refetch } = usePaginatedQuery(
    ["agencies", pageCount],
    fetchAgency
  );
  const { data: category } = useQuery("categories", fetchCategory);
  const [open, setOpen] = React.useState(false);
  const [openAgency, setOpenAgency] = React.useState(false);
  const [openAgencyEdit, setOpenAgencyEdit] = React.useState(false);

  const viewAgency = (agency) => {
    setAgency(agency);
    setOpenAgency(true);
  };

  const editAgency = (agency) => {
    setForm({
      ...form,
      name: agency.name,
      about: agency.about,
      web_url: agency.web_url,
      email: agency.email,
      acronym: agency.acronym,
      id: agency.id
    });
    setOpenAgencyEdit(true);
  };

  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  const onSelectCategory = (e) => {
    if (form.category_ids.includes(e.target.value)) {
      setForm({ ...form, category_ids: [e.target.value] });
    } else {
      setForm({
        ...form,
        category_ids: [...form.category_ids, e.target.value],
      });
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteAgency(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onPostAgency = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const {  hasError, errors, message } = res.data;

      console.log(res.data);
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: message });
        swal("Success", message, "success");
        setForm({});
        refetch();
        setOpen(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onEditAgency = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEdit({ form });
      const {hasError, errors, message } = res.data;

      console.log(res.data);
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: message });
        swal("Success", message, "success");
        setForm({});
        refetch();
        setOpenAgencyEdit(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onDeleteAgency = async (id) => {
    try {
      const res = await mutateDelete(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message, "", "error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const viewMore = () => {
    setPageCount(pageCount + 6);
  };

  return (
    <>
      <MainLayout>
        <div className="agencies">
          <p className="page-title">Agencies</p>
          <section className="create">
            <CardBox>
              <DialogBox
                open={open}
                close={() => setOpen(false)}
                buttonTitle={
                  <span onClick={() => setOpen(true)}>
                    <i
                      className="fa fa-plus-square-o fa-lg icon-create"
                      aria-hidden="true"
                    ></i>
                    create agencies
                  </span>
                }
                buttonOk={
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={onPostAgency}
                  >
                    {isLoading && (
                      <Spinner animation="border" className="ml-2" size="sm" />
                    )}{" "}
                    add agency
                  </button>
                }
              >
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}
                  {/* <div className="dflex justify-content-between">
               
                  {form.category_ids.map(cate => <p>{cate}</p>)}
                </div> */}

                  <div className="avatar-cont">
                    <img id="output" width="150px" height="150px" />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="agency name"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput2"
                      placeholder="email"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput2"
                      placeholder="website"
                      value={form.web_url}
                      onChange={(e) =>
                        setForm({ ...form, web_url: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <select
                      className="custom-select form-control form-control-sm "
                      id="inputGroupSelect01"
                      value={form.category_ids}
                      onChange={onSelectCategory}
                    >
                      <option selected>select category</option>
                      {category?.categories.map((cat, i) => {
                        return (
                          <option value={cat.id} key={i}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput2"
                      placeholder="acronym"
                      value={form.acronym}
                      onChange={(e) =>
                        setForm({ ...form, acronym: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="output"
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                      multiple
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="description"
                      className="form-control form-control-sm"
                      id="desc"
                      cols="30"
                      rows="3"
                      placeholder="description"
                      value={form.about}
                      onChange={(e) =>
                        setForm({ ...form, about: e.target.value })
                      }
                    ></textarea>
                  </div>
                </form>
              </DialogBox>
            </CardBox>
          </section>

          <section className="mt-3 agency-table">
            <CardBox>
              <p>Top agencies</p>
              <Table responsive hover size="md">
                <thead className="agency-table-head">
                  <tr>
                    <th>AGENCY</th>
                    {/* <th>CATEGORY</th> */}
                    <th>ALL TASK</th>
                    <th className="text-warning">PENDING</th>
                    <th className="text-success">COMPLETED</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {resolvedData?.agencies.map((agency, i) => {
                    return (
                      <tr key={i}>
                        <td>{agency.name}</td>
                        <td>{agency.total_task}</td>
                        <td>{agency.total_pending}</td>
                        <td>{agency.total_completed}</td>
                        <td>
                          <button
                            onClick={() => viewAgency(agency)}
                            className="btn btn-sm btn-info mr-2"
                          >
                          
                            view
                          </button>
                          <button
                            onClick={() => editAgency(agency)}
                            className="btn btn-sm btn-secondary mr-2"
                          >
                        
                            edit
                          </button>
                        
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <button
                className="btn btn-sm btn btn-outline-secondary"
                onClick={viewMore}
              >
                next
              </button>
            </CardBox>
            <DialogBox
              open={openAgency}
              close={() => setOpenAgency(false)}
              buttonOk={
                <button
                  className="btn btn-dark btn-sm"
                  onClick={() => setOpenAgency(false)}
                >
                  ok
                </button>
              }
            >
              <div>
                <img src={agency.logo} width="30%" alt="logo" />
                <p>{agency.name}</p>
                <p>{agency.about}</p>
                <p>{agency.email}</p>
                <p>{agency.web_url}</p>
                <p>{agency.acronym}</p>
              </div>
            </DialogBox>

            <DialogBox
              open={openAgencyEdit}
              close={() => setOpenAgencyEdit(false)}
              buttonOk={
                <button className="btn btn-dark btn-sm" onClick={onEditAgency}>
                  {agencyEdit && (
                    <Spinner animation="border" className="ml-2" size="sm" />
                  )}{" "}
                  edit agency
                </button>
              }
            >
              <form>
                {form.error && (
                  <small className="text-danger mb-4">{form.message}</small>
                )}
                {!form.error && (
                  <small className="text-success mb-2">{form.message}</small>
                )}

                {/* 
                  <div className="avatar-cont">
                    <img id="output" width="150px" height="150px" />
                  </div> */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="exampleFormControlInput1"
                    placeholder="agency name"
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="exampleFormControlInput2"
                    placeholder="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="exampleFormControlInput2"
                    placeholder="website"
                    value={form.web_url}
                    onChange={(e) =>
                      setForm({ ...form, web_url: e.target.value })
                    }
                  />
                </div>
                {/* <div className="form-group mb-3">
                    <select
                      className="custom-select form-control form-control-sm "
                      id="inputGroupSelect01"
                      value={form.category_ids}
                      onChange={onSelectCategory}
                    >
                      <option selected>select category</option>
                      {category?.categories.map((cat, i) => {
                        return (
                          <option value={cat.id} key={i}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="exampleFormControlInput2"
                    placeholder="acronym"
                    value={form.acronym}
                    onChange={(e) =>
                      setForm({ ...form, acronym: e.target.value })
                    }
                  />
                </div>
                {/* <div className="form-group">
                    <input
                      id="output"
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                      multiple
                      required
                    />
                  </div> */}
                <div className="form-group">
                  <textarea
                    name="description"
                    className="form-control form-control-sm"
                    id="desc"
                    cols="30"
                    rows="3"
                    placeholder="description"
                    value={form.about}
                    onChange={(e) =>
                      setForm({ ...form, about: e.target.value })
                    }
                  ></textarea>
                </div>
              </form>
            </DialogBox>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Agency;
