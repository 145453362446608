import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import {
  fetchFaq,
  postFaq, deleteFaq,postEditFaq
} from "../../queries/faq";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import swal from "sweetalert";

const Faq = (props) => {
  const [form, setForm] = useState({
    name: "",
    about: "",
    message: "",
    error: false,
  });
  const [mutate, { isLoading }] = useMutation(postFaq);
  const [mutateEdit, {isLoading:editLoad}] = useMutation(postEditFaq);
  const [mutateDelete] = useMutation(deleteFaq);

  const { status, data, refetch } = useQuery("faqs", fetchFaq);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const editFaq = (faq) => {
    setForm({
      ...form,
      question: faq.question,
      answer: faq.answer,
      id: faq.id
    });
    setOpenEdit(true);
  };



  const onPostFaq = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newCat.message, question:"", answer:"" });
        refetch();
        setOpen(false);
        swal(newCat.message,"","success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteFaq(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onDeleteFaq = async (id) => {
    try {
      const res = await mutateDelete(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message, "", "error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onEditFaq = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEdit({ form });
      const {hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, question:"", answer:"" });
        refetch();
        setOpenEdit(false);
        swal(message,"","success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  return (
    <>
      <MainLayout>
        <div className="faq">
          <p className="page-title">Frequently Asked Questions</p>
          <section className="create">
            <CardBox>
              <DialogBox
                open={open}
                close={() => setOpen(false)}
                buttonTitle={
                  <span onClick={() => setOpen(true)}>
                    <i
                      className="fa fa-plus-square-o fa-lg icon-create"
                      aria-hidden="true"
                    ></i>
                    create new FAQ
                  </span>
                }
                buttonOk={
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={onPostFaq}
                  >
                    {isLoading && (
                      <Spinner animation="border" className="ml-2" size="sm" />
                    )}{" "}
                    add faq
                  </button>
                }
              >
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="question"
                      value={form.question}
                      onChange={(e) =>
                        setForm({ ...form, question: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <textarea name="answer" className="form-control form-control-sm" id="desc" cols="30" rows="5"  placeholder="answer"
                      value={form.answer}
                      onChange={(e) =>
                        setForm({ ...form, answer: e.target.value })
                      }></textarea>
                   
                  </div>
                </form>
              </DialogBox>
            </CardBox>
          </section>

          <section className="mt-3 cat-table row">
            <div className="col-md-12">
              <CardBox>
                <p>Top FAQ</p>

                <Table responsive hover size="md">
                  <thead className="cat-table-head">
                    <tr>
                      <th>QUESTION</th>
                      <th>ANSWER</th>
                      <th>DATE</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.faqs.map((faqq, i) => {
                      return (
                        <tr key={i}>
                          <td>{truncate(faqq.question,50)}</td>
                          <td>{truncate(faqq.answer, 60)}</td>
                          <td>
                            {dayjs(faqq.createdAt).format("MMM DD")}
                          </td>
                                                   <td>
                          <button
                            onClick={() => editFaq(faqq)}
                            className="btn btn-sm btn-secondary "
                          >
                            <small>edit</small>
                          </button>
                          </td>
                        <td>
                          <button
                            onClick={() => handleDelete(faqq.id)}
                            className="btn btn-sm btn-danger "
                          >
                           <small>delete</small>
                          </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {status === "loading" && (
                  <div className="text-center p-5">
                    <Spinner
                      animation="border"
                      className="ml-2 text-center text-success"
                      size="lg"
                    />
                  </div>
                )}
              </CardBox>
            </div>
            {/* <div className="col-md-4">
              <CardBox>
                <p>Quick details</p>

                <Table responsive hover size="md">
                  <tbody>
                    {data?.faqs.map((faqqq, i) => {
                      return (
                        <tr key={i}>
                          <td> ✅ {faqqq.name}</td>
                          <td>{faqqq.number_ofof_infractions}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBox>
            </div> */}

<DialogBox
                open={openEdit}
                close={() => setOpenEdit(false)}
               
                buttonOk={
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={onEditFaq}
                  >
                    {editLoad && (
                      <Spinner animation="border" className="ml-2" size="sm" />
                    )}{" "}
                    edit faq
                  </button>
                }
              >
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="question"
                      value={form.question}
                      onChange={(e) =>
                        setForm({ ...form, question: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <textarea name="answer" className="form-control form-control-sm" id="desc" cols="30" rows="5"  placeholder="answer"
                      value={form.answer}
                      onChange={(e) =>
                        setForm({ ...form, answer: e.target.value })
                      }></textarea>              
                  </div>
                </form>
              </DialogBox>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Faq;
