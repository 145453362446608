import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const postPioneerPartnerProduct = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const fd = new FormData();
  fd.append("name", form.name);
  fd.append("image", form.image, "Image 14@2x.png");
  fd.append("partner_id", form.partner_id);
  fd.append("description", form.description);

  const res = await Axios.post(API_CONSTANT_MAP.pioneer_partner_product.create, fd, {
    headers,
  });
  return res;
};

export const postEditPioneerPartnerProduct = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const rawData = {
    ...(form.name && { name: form.name }),
    ...(form.partner_id && { partner_id: form.partner_id }),
    ...(form.description && { description: form.description }),
  };
  const res = await Axios.put(
    `${API_CONSTANT_MAP.pioneer_partner_product.edit}${form.id}`,
    JSON.stringify(rawData),
    {
      headers,
    }
  );
  return res;
};

export const fetchPioneerPartnerProduct = async ({ params }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(
    `${API_CONSTANT_MAP.pioneer_partner_product.fetchAll}${params || "ALL"}`,
    {
      headers,
    }
  );
  return res.data.data;
};

export const deletePioneerPartnerProduct = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.pioneer_partner_product.delete}${id}`, {
    headers,
  });
  return res;
};
