import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const postNotification = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    message: form.message,
    title: form.title,
    topic: form.topic,
  };

  const res = await Axios.post(API_CONSTANT_MAP.notification.create, data, {
    headers,
  });
  return res;
};

export const fetchNotification = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(API_CONSTANT_MAP.notification.fetchAll, {
    headers,
  });
  return res.data.data;
};

export const deleteNotification = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  console.log("hit here");
  const res = await Axios.delete(`${API_CONSTANT_MAP.notification.delete}${id}`, {
    headers,
  });
  return res;
};
