import React, { useState, useEffect } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import { fetchSearchInfraction } from "../../queries/infractions";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
//import CardInfraction from "../../components/CardInfraction/CardInfraction";
import { useHistory } from "react-router-dom";
import ViewMore from "../../components/ViewMore/ViewMore";
import CardInfractionAgency from "../../components/CardInfractionAgency/CardInfractionAgency";

const Search = (props) => {
  const history = useHistory();
  const {
    match: { params },
    location,
  } = props;
  const searchparam = new URLSearchParams(location.search);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState({
    text: searchparam.get("q"),
  });
  const { status, data, refetch} = useQuery(
    ["search-infractions", search.text],
    fetchSearchInfraction
  );

  const viewMore = () => {
    refetch();
    setPageCount(pageCount + 1);
  }

  useEffect(()=>{
    refetch();
  },[]);

  return (
    <>
      <MainLayout>
        <div className="infraction">
        
          {status === "loading" && (
            <div className="text-center p-5">
              <Spinner
                animation="border"
                className="ml-2 text-center text-success"
                size="lg"
              />
            </div>
          )}

          {data?.infractions.length < 1 && (
            <div className="text-center p-5">
              <h6>Could not find infraction</h6>
            </div>
          )}

          <section className="infract-content mt-4">
            <div className="row">
              {data?.infractions.map((infraction, i) => {
                return (
                  <div className="col-md-4 mb-4">
                    <CardInfractionAgency
                      status={infraction.status}
                      key={i}
                      navigate={() =>
                        history.push(`/infraction/${infraction.id}`)
                      }
                      infraction={infraction}
                    />
                  </div>
                );
              })}
            </div>
            <ViewMore viewHandler={viewMore} />
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Search;
