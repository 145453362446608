import React from "react";
import { MainLayout } from "../components";
const ErrorPage = (props) => {
  return (
    <MainLayout>
    <section>
      <div className="text-center pt-5">
        <h1>404</h1>
        <h6>Page not found !</h6>
      </div>
    </section>
    </MainLayout>
  );
};

export default ErrorPage;
