import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const postLga = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    name: form.name,
    about: form.about,
  };

  const res = await Axios.post(API_CONSTANT_MAP.lga, data, {
    headers,
  });
  return res;
};

export const postEditLga = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    name: form.name,
    about: form.about,
    id: form.id,
  };

  const res = await Axios.put(`${API_CONSTANT_MAP.lga}`, data, {
    headers,
  });
  return res;
};

export const postDeleteLga = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.lga}/${id}`, {
    headers,
  });
  return res;
};

export const fetchLga = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(API_CONSTANT_MAP.lga, {
    headers,
  });
  return res.data.data;
};

export const fetchLgaById = async (params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.lga}/${params}`, {
    headers,
  });
  return res;
};
