import React from 'react'
import './styles.scss'

const BackButton = props => {
    const { back } = props
    return (
      <div className="back-container">
      <button className="btn btn-sm btn btn-outline-secondary back" onClick={back}>
        back
      </button>
    </div>
    )
}

export default BackButton;