import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import {
  fetchAdminUser,
  fetchAllUser,
  postCreateUser,
  postUpdateUser,
  searchUsers,
  postUpdateSuspendStatus,
} from "../../queries/user";
import {
  fetchLogs,
  fetchSuspensionSettings,
  updateSuspensionSettings,
} from "../../queries/suspension_settings";
import { useQuery, useMutation, usePaginatedQuery } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import { Auth } from "../../config";
import swal from "sweetalert";

import { fetchAgency } from "../../queries/agency";
import { LoadScript } from "@react-google-maps/api";

const Settings = (props) => {
  const history = useHistory();
  const [form, setForm] = useState({
    content: "",
    message: "",
    error: false,
  });

  const [userform, setUserForm] = useState({
    content: "",
    message: "",
    error: false,
  });

  useEffect(() => {
    const user = Auth.getUser();
    setUserForm({
      ...form,
      firstname: user.first_name,
      lastname: user.last_name,
      phone: user.phone,
      email: user.email,
      admin: user.user_role,
      agency_id: user.agency_id,
      profile_picture: user.profile_picture,
    });
    setUser(user);
  }, []);

  const [page, setPage] = useState("users");
  const [file, setFile] = useState();
  const [user, setUser] = useState({});
  const [userInfo, setuserInfo] = useState({ name: "" });
  const [pageCount, setPageCount] = useState(12);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPageCount, setSearchPageCount] = useState(12);

  const [mutate, { isLoading }] = useMutation(postCreateUser);
  const [mutateAssign, { isLoading: assignLoading }] =
    useMutation(postUpdateUser);

  const {
    status: adminStatus,
    resolvedData: adminData,
    refetch,
  } = usePaginatedQuery(["admin", pageCount], fetchAdminUser);
  const {
    status: userStatus,
    resolvedData: userData,
    refetch: userRefetch,
  } = usePaginatedQuery(["users", pageCount], fetchAllUser);
  const {
    status: searchStatus,
    resolvedData: searchData,
    refetch: refetchSearch,
    isLoading: searchLoading,
    isFetching: searchFetching,
  } = usePaginatedQuery(
    ["users_search", searchQuery, searchPageCount],
    searchUsers
  );

  const [mutateStatusUpdate, { isLoading: statusUpdateLoading }] = useMutation(
    postUpdateSuspendStatus
  );
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAssign, setOpenAssign] = React.useState(false);

  const { isLoading: agencyLoad, data: agencyData } = useQuery(
    "agencies",
    fetchAgency
  );

  const {
    isLoading: settingsLoading,
    data: suspensionSettings,
    refetch: refetchSuspensionSettings,
  } = useQuery("fetch_suspension_settings", fetchSuspensionSettings);
  const [mutateSuspensionSettings, { isLoading: settingsUpdating }] =
    useMutation(updateSuspensionSettings);

  const onAssign = (email) => {
    setForm({ ...form, email: email, message: "", role: "" });
    setOpenAssign(true);
  };

  const onAssignUser = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateAssign({ form });
      const { hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: message });
        userRefetch();
        setOpenAssign(false);
        swal("Assigned successfully", message, "success");
        setForm({ ...form, email: "", role: "", agency_id: "" });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onPostUser = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        setOpen(false);
        swal("Created successfully", message, "success");
        setForm({});
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);

    // fileObj.push(e.target.files);
    // for (let i = 0; i < fileObj[0].length; i++) {
    //   fileArray.push(URL.createObjectURL(fileObj[0][i]));
    // }
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  const viewMore = () => {
    if (searchQuery.length > 0 && searchData) {
      setSearchPageCount(searchPageCount + 6);
    } else {
      setPageCount(pageCount + 6);
    }
  };

  const getDataToUse = () => {
    if (searchQuery.length > 0 && searchData) {
      return searchData;
    } else {
      return userData;
    }
    // return userData;
  };

  const updateStatusHandler = async (user) => {
    try {
      const { id, is_suspended } = user;
      const res = await mutateStatusUpdate({ id, is_suspended: !is_suspended });
      const { hasError, errors, message } = res.data;
      if (hasError) {
        NotificationManager.error("Failed", errors.message);
      } else {
        NotificationManager.success("Success", message);
        userRefetch();
        if (searchQuery.length > 0 && searchData) refetchSearch();
      }
    } catch (error) {
      NotificationManager.error("Failed", error);
    }
  };

  const updateSuspendedSettingHandler = async (user_role, is_suspended) => {
    try {
      const res = await mutateSuspensionSettings({
        user_role,
        is_suspended: !is_suspended,
      });
      const { hasError, errors, message } = res;
      if (hasError) {
        NotificationManager.error("Failed", errors.message);
      } else {
        NotificationManager.success("Success", message);
        refetchSuspensionSettings();
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
      NotificationManager.error("Failed", error);
    }
  };

  return (
    <>
      <MainLayout>
        <NotificationContainer />
        <div className="settings">
          <section className="infrac-nav">
            <CardBox>
              <div className="nav-infrac">
                <div className="col-md-2">
                  <p
                    onClick={() => {
                      setPage("users");
                    }}
                    className={page === "users" ? "active-btn" : ""}
                  >
                    Users
                  </p>
                </div>
                <div className="col-md-2">
                  <p
                    onClick={() => {
                      setPage("admin");
                    }}
                    className={page === "admin" ? "active-btn" : ""}
                  >
                    Admin
                  </p>
                </div>
                <div className="col-md-2">
                  <p
                    onClick={() => {
                      setPage("account");
                    }}
                    className={page === "account" ? "active-btn" : ""}
                  >
                    Account
                  </p>
                </div>
                <div className="col-md-2">
                  <p
                    onClick={() => {
                      setPage("user-settings");
                    }}
                    className={page === "user-settings" ? "active-btn" : ""}
                  >
                    Access
                  </p>
                </div>{" "}
                <div className="col-md-4 search-users">
                  <input
                    type="text"
                    name="search"
                    className="ml-1 mr-1 search-user-input"
                    id=""
                    value={searchQuery}
                    placeholder="search user by name, email, or phone number"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    disabled={page !== "users"}
                  />
                  <i
                    className="fa fa-search search-icon"
                    aria-hidden="true"
                    color="#088E4A"
                    onClick={() => refetchSearch()}
                  ></i>
                </div>
              </div>
            </CardBox>
          </section>

          {page === "users" && (
            <section className="mt-3 settings-table">
              {searchQuery.length > 0 && (
                <div
                  className="search-title"
                  onClick={() => setSearchQuery("")}
                >
                  <small>
                    search results{" "}
                    <span className="ml-2">
                      {searchFetching ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i className="fa fa-remove" aria-hidden="true"></i>
                      )}
                    </span>
                  </small>
                </div>
              )}
              <CardBox>
                <Table responsive hover size="md">
                  <thead className="settings-table-head">
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Agency</th>
                      <th>Phone number</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDataToUse()?.users.length < 1 ? (
                      <tr>
                        <td>
                          <p className="no-data">
                            Sorry, your search -<span>{searchQuery}</span>- does
                            not match any user
                          </p>
                        </td>
                      </tr>
                    ) : (
                      getDataToUse()?.users.map((user, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <img
                                src={user.profile_picture}
                                id="output"
                                width="50px"
                                height="50px"
                                className="img-avatar"
                              />
                            </td>
                            <td
                              className="username"
                              onClick={() =>
                                history.push(`/user-activity/${user.id}`)
                              }
                              title={"view " + user.first_name + "'s profile"}
                            >
                              {user.first_name} {user.last_name}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.user_role}</td>
                            <td>
                              {user.is_suspended ? (
                                <span className="badge badge-warning">
                                  suspended
                                </span>
                              ) : (
                                <span className="badge badge-success">
                                  active
                                </span>
                              )}
                            </td>
                            <td>
                              {user.agency_id
                                ? user?.agency?.acronym
                                : "no agency"}
                            </td>
                            <td>{user.phone}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-info mr-3"
                                onClick={() => onAssign(user.email)}
                              >
                                <small>assign</small>
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-warning btn-sm"
                                onClick={() => updateStatusHandler(user)}
                              >
                                {user.is_suspended ? (
                                  <small>restore</small>
                                ) : (
                                  <small>suspend</small>
                                )}
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() =>
                                  history.push(`/user-activity/${user.id}`)
                                }
                              >
                                <small>
                                  view{" "}
                                  <i className="fa fa-eye" aria-hidden="true" />
                                </small>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>

                <button
                  className="btn btn-sm btn btn-outline-secondary"
                  onClick={viewMore}
                >
                  next
                </button>
              </CardBox>
            </section>
          )}

          {page === "admin" && (
            <>
              <section className="create">
                <CardBox>
                  <DialogBox
                    open={open}
                    close={() => setOpen(false)}
                    buttonTitle={
                      <span onClick={() => setOpen(true)}>
                        <i
                          className="fa fa-plus-square-o fa-lg icon-create"
                          aria-hidden="true"
                        ></i>
                        create admin
                      </span>
                    }
                    buttonOk={
                      <button
                        className="btn btn-dark btn-sm"
                        onClick={onPostUser}
                      >
                        {isLoading && (
                          <Spinner
                            animation="border"
                            className="ml-2"
                            size="sm"
                          />
                        )}{" "}
                        Add Admin
                      </button>
                    }
                  >
                    <form>
                      {form.error && (
                        <small className="text-danger mb-4">
                          {form.message}
                        </small>
                      )}
                      {!form.error && (
                        <small className="text-success mb-2">
                          {form.message}
                        </small>
                      )}

                      <div className="avatar-cont">
                        <img
                          src={user.profile_picture}
                          id="output"
                          width="150px"
                          height="150px"
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="first name"
                          value={form.firstname}
                          onChange={(e) =>
                            setForm({ ...form, firstname: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="last name"
                          value={form.lastname}
                          onChange={(e) =>
                            setForm({ ...form, lastname: e.target.value })
                          }
                        />
                      </div>
                      <div className="input-group mb-3">
                        <select
                          className="custom-select product-select"
                          id="inputGroupSelect01"
                          value={form.agency_id}
                          onChange={(e) =>
                            setForm({ ...form, agency_id: e.target.value })
                          }
                        >
                          <option selected>select agency</option>
                          {agencyData?.agencies.map((agency, i) => {
                            return (
                              <option value={agency.id} key={i}>
                                {agency.acronym}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <select
                          className="custom-select form-control form-control-sm "
                          id="inputGroupSelect01"
                          value={form.role}
                          onChange={(e) =>
                            setForm({ ...form, role: e.target.value })
                          }
                        >
                          <option selected>select roles</option>
                          <option value="staff">STAFF</option>
                          <option value="admin">ADMIN</option>
                          <option value="superAdmin">SUPER ADMIN</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          id="output"
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="phone number"
                          value={form.phone}
                          onChange={(e) =>
                            setForm({ ...form, phone: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="email"
                          value={form.email}
                          onChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                        />
                      </div>
                      <small>
                        Users will be asked to update their password next time
                        they login
                      </small>
                    </form>
                  </DialogBox>
                </CardBox>
              </section>
              <section className="mt-3 settings-table">
                {/* {adminStatus === "loading" && (
                  <div className="text-center p-5">
                    <Spinner
                      animation="border"
                      className="ml-2 text-center text-success"
                      size="lg"
                    />
                  </div>
                )} */}
                <CardBox>
                  <Table responsive hover size="md">
                    <thead className="settings-table-head">
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Status</th>
                        <th>Agency</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {adminData?.users.map((user, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <img
                                src={user.profile_picture}
                                id="output"
                                width="50px"
                                height="50px"
                                className="img-avatar"
                              />
                            </td>
                            <td>
                              {user.first_name} {user.last_name}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.user_role}</td>
                            <td>
                              {user.is_suspended ? (
                                <span className="badge badge-warning">
                                  suspended
                                </span>
                              ) : (
                                <span className="badge badge-success">
                                  active
                                </span>
                              )}
                            </td>
                            <td>
                              {user.agency_id
                                ? user?.agency.acronym
                                : "no agency"}
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-info mr-3"
                                onClick={() => onAssign(user.email)}
                              >
                                <small>assign</small>
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-warning btn-sm"
                                onClick={() => updateStatusHandler(user)}
                              >
                                {user.is_suspended ? (
                                  <small>restore</small>
                                ) : (
                                  <small>suspend</small>
                                )}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <button
                    className="btn btn-sm btn btn-outline-secondary"
                    onClick={viewMore}
                  >
                    next
                  </button>
                </CardBox>
              </section>
            </>
          )}

          {page === "account" && (
            <section className="account-update">
              <CardBox>
                <form>
                  {form.error && (
                    <small className="text-danger mb-4">{form.message}</small>
                  )}
                  {!form.error && (
                    <small className="text-success mb-2">{form.message}</small>
                  )}

                  <div className="avatar-cont">
                    <img
                      src={userform?.profile_picture}
                      id="output"
                      width="150px"
                      height="150px"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="first name"
                      value={userform.firstname}
                      onChange={(e) =>
                        setUserForm({ ...userform, firstname: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="last name"
                      value={userform.lastname}
                      onChange={(e) =>
                        setUserForm({ ...userform, lastname: e.target.value })
                      }
                    />
                  </div>

                  {/* <div className="input-group mb-3">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      value={userform.agency_id}
                      onChange={(e) =>
                        setUserForm({ ...userform, agency_id: e.target.value })
                      }
                    >
                      <option selected>select agency</option>
                      {agencyData?.agencies.map((agency, i) => {
                        return (
                          <option value={agency.id} key={i}>
                            {agency.acronym}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  {/* <div className="form-group mb-3">
                    <select
                      className="custom-select form-control form-control-sm "
                      id="inputGroupSelect01"
                      value={userform.admin}
                      onChange={(e) =>
                        setUserForm({ ...userform, admin: e.target.value })
                      }
                    >
                      <option selected>select roles</option>
                      <option value="staff">STAFF</option>
                      <option value="admin">ADMIN</option>
                      <option value="superAdmin">SUPER ADMIN</option>
                    </select>
                  </div> */}
                  <div className="form-group">
                    <input
                      id="output"
                      type="file"
                      className="form-control"
                      onChange={uploadMultipleFiles}
                      multiple
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="phone number"
                      value={userform.phone}
                      onChange={(e) =>
                        setUserForm({ ...userform, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="email"
                      value={userform.email}
                      onChange={(e) =>
                        setUserForm({ ...userform, email: e.target.value })
                      }
                    />
                  </div>

                  <button className="btn btn-success float-right">
                    Update
                  </button>
                </form>
              </CardBox>
            </section>
          )}
          {page === "user-settings" && (
            <div className="mt-3 settings-table">
              <CardBox>
                <Table responsive hover size="md">
                  <thead className="settings-table-head">
                    <tr>
                      <th>Role</th>
                      <th>Suspended</th>
                    </tr>
                  </thead>
                  <tbody>
                    {suspensionSettings?.suspension_settings.map(
                      (setting, i) => {
                        return (
                          <tr key={i}>
                            <td>{setting.user_role}</td>
                            <td
                              className={
                                setting.is_suspended
                                  ? "toggled-on toggle-td"
                                  : "toggle-td"
                              }
                              onClick={() =>
                                updateSuspendedSettingHandler(
                                  setting.user_role,
                                  setting.is_suspended
                                )
                              }
                            >
                              {setting.is_suspended ? (
                                <i
                                  className="fa fa-toggle-on"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-toggle-off"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </CardBox>
            </div>
          )}

          <DialogBox
            open={openAssign}
            close={() => setOpenAssign(false)}
            buttonOk={
              <button className="btn btn-dark btn-sm" onClick={onAssignUser}>
                {assignLoading && (
                  <Spinner animation="border" className="ml-2" size="sm" />
                )}{" "}
                assign
              </button>
            }
          >
            <form>
              {form.error && (
                <small className="text-danger mb-4">{form.message}</small>
              )}
              {!form.error && (
                <small className="text-success mb-2">{form.message}</small>
              )}
              <div className="form-group mb-3">
                <select
                  className="custom-select form-control form-control-sm "
                  id="inputGroupSelect01"
                  value={form.role}
                  onChange={(e) => setForm({ ...form, role: e.target.value })}
                >
                  <option selected>select roles</option>
                  <option value="staff">STAFF</option>
                  <option value="admin">ADMIN</option>
                  <option value="superAdmin">SUPER ADMIN</option>
                </select>
              </div>
              <div className="input-group mb-3">
                <select
                  className="custom-select product-select"
                  id="inputGroupSelect01"
                  value={form.agency_id}
                  onChange={(e) =>
                    setForm({ ...form, agency_id: e.target.value })
                  }
                >
                  <option selected>select agency</option>
                  {agencyData?.agencies.map((agency, i) => {
                    return (
                      <option value={agency.id} key={i}>
                        {agency.acronym}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </DialogBox>
        </div>
      </MainLayout>
    </>
  );
};

export default Settings;
