import React from "react";
import "./styles.scss";

export default function CardBox(props) {
  return (
    <>
      <div className="card-box" style={props.style}>
        {props.children}
      </div>
    </>
  );
}
