import React, { Fragment, useState } from "react";
import "./styles.scss";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ROUTES from "../../routes";
import auth2 from "../../images/auth2.png";
import citi from "../../images/citi.svg";
import { useMutation } from "react-query";

import { Auth } from "../../config";
import { postLogin } from "../../queries/user";

const Signin = (props) => {
  const history = useHistory();
  const [mutate, { isLoading }] = useMutation(postLogin);
  const [form, setForm] = useState({
    email: "",
    password: "",
    message: "",
    error: false,
  });

  const onPostLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data, hasError, errors, message } = res.data;
      if (hasError) {
        NotificationManager.error('Failed message', errors.message);
      } else {
        if(data.user.user_role === 'user'){
          setForm({ password: "" });
          return NotificationManager.error('Failed message', 'Unauthorized User');
        }
        Auth.setToken(data.loginToken, data.user);
        NotificationManager.success('Success', 'Login success');
        //setForm({ ...form, password: "", message: message });
        if(data.user.agency.acronym === 'MOEWR'){
          history.push("/dashboard");
        } else {
          history.push("/dashboard-agency");
        }
       
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
      NotificationManager.error('Failed message', 'Network Error');
      setForm({ password: "" });
    }
  };

  return (
    <Fragment>
      <NotificationContainer/>
      <main>
        <section className="page-image">
          <div className="page-image-welcome">
            <p className="welcome-text">
              Welcome to Citi Monitor Think green, think clean, think Lagos.
            </p>
          </div>
        </section>
        <section className="page-form">
          <div className="page-form-container">
            <div className="page-form-fields">
              <img src={citi} alt="citi" width="70%" />
              <p className="auth-text mt-3">
                Welcome back! Please login to your account.
              </p>
              <Form onSubmit={onPostLogin}>

                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Official email"
                    className="auth-input"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className="auth-input"
                    value={form.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <div className="form-remember">
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Remember me"
                      className=""
                    />
                  </Form.Group>
                  <Link to={ROUTES.FORGOT_PASSWORD} className="">
                    Forgot Password?
                  </Link>
                </div>
                <Button variant="success" className="auth-button" type="submit">
                  Login  {isLoading && (
                    <Spinner animation="border" className="ml-2" size="sm" />
                  )}
                </Button>
              </Form>
            </div>
          </div>
            <div className="page-form-image-footer">
              <p className="auth-text">Powered by</p>
              <img src={auth2} width="70%" alt="lag" />
            </div>
        </section>
      </main>
    </Fragment>
  );
};

export default Signin;
