import React, { useState } from "react";
import { MainLayout } from "../../components";
import "./styles.scss";
import CardBox from "../../components/CardBox/CardBox";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import gold from "../../images/gold.svg";
import platinum from "../../images/platinum.svg";
import DialogBox from "../../components/DialogBox/DialogBox";
import swal from "sweetalert";

import {
  deletePioneerPartner,
  fetchPioneerPartner,
  postEditPioneerPartner,
  postPioneerPartner,
} from "../../queries/pioneer_partner";

import {
  deletePioneerPartnerProduct,
  fetchPioneerPartnerProduct,
  postEditPioneerPartnerProduct,
  postPioneerPartnerProduct,
} from "../../queries/pioneer_partner_product";

const Pioneer_Partners = () => {
  const [form, setForm] = useState({
    title: "",
    content: "",
    message: "",
    error: false,
  });

  const [page, setPage] = useState("pioneer-partners");
  const [partnerInfo, setPartnerInfo] = useState({
    id: "",
    name: "",
    category: "",
    web_url: "",
    logo: "",
    message: "",
    environmental_efforts: "",
    partner_products: [],
  });
  const [productInfo, setProductInfo] = useState({
    id: "",
    partner_id: "",
    name: "",
    media_url: "",
    description: "",
  });

  const [mutate, { isLoading }] = useMutation(postPioneerPartner);
  const [mutateEditPioneerPartner, { isLoading: editLoad }] = useMutation(postEditPioneerPartner);
  const [mutateDeletePioneerPartner] = useMutation(deletePioneerPartner);

  const [mutatedProduct, { isLoading: loadingProducts }] = useMutation(postPioneerPartnerProduct);
  const [mutateEditProduct, { isLoading: productEditLoading }] = useMutation(
    postEditPioneerPartnerProduct
  );
  const [mutateDeletePioneerPartnerProduct] = useMutation(deletePioneerPartnerProduct);

  const { data, refetch } = useQuery("pioneer_partners", fetchPioneerPartner);
  const { data: productsData, refetch: refetchProducts } = useQuery(
    "pioneer_partners_products",
    fetchPioneerPartnerProduct
  );

  const onPostPioneerPartner = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newCat.message });
        refetch();
        swal("Created", message, "success");
        setOpen(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onPostProduct = async (e) => {
    e.preventDefault();
    try {
      const res = await mutatedProduct({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newCat.message });
        refetchProducts();
        refetch();
        swal("Created", message, "success");
        setOpenProduct(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const viewPioneerPartner = (pioneer_partner) => {
    setPartnerInfo(pioneer_partner);
  };
  const viewProduct = (product) => {
    setProductInfo(product);
  };
  const switchToViewProduct = (product) => {
    setPage("products");
    viewProduct(product);
  };
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openEditProduct, setOpenEditProduct] = React.useState(false);

  const onEditPioneerPartner = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEditPioneerPartner({ form });
      const { hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, question: "", answer: "" });
        refetch();
        setOpenEdit(false);
        swal(message, "", "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const editPioneerPartner = (pioneer_partner) => {
    setForm({
      ...form,
      name: pioneer_partner.name,
      category: pioneer_partner.category,
      id: pioneer_partner.id,
      web_url: pioneer_partner.web_url,
      messageToLagos: pioneer_partner.message,
      environmental_efforts: pioneer_partner.environmental_efforts,
    });
    setOpenEdit(true);
  };

  const onEditProduct = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEditProduct({ form });
      const { hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, question: "", answer: "" });
        refetchProducts();
        refetch();
        setOpenEditProduct(false);
        swal(message, "", "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const editProduct = (product) => {
    setForm({
      ...form,
      name: product.name,
      id: product.id,
      partner_id: product.partner_id,
      description: product.description,
    });
    setOpenEditProduct(true);
  };

  const handleDeletePioneerPartner = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeletePioneerPartner(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onDeletePioneerPartner = async (id) => {
    try {
      const res = await mutateDeletePioneerPartner(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message, "", "error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const handleDeleteProduct = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteProduct(id);
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  const onDeleteProduct = async (id) => {
    try {
      const res = await mutateDeletePioneerPartnerProduct(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message, "", "error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetchProducts();
        refetch();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const splitEnvironmentalEfforts = (environmental_efforts) => {
    const efforts = environmental_efforts.split(";");
    const validEfforts = efforts.filter((effort) => effort.length > 0);
    return validEfforts;
  };

  const [file, setFile] = useState();
  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  return (
    <MainLayout>
      <div className="pioneer-partners">
        Pioneer Partners
        <section className="partners-nav">
          <CardBox>
            <div className="nav-partner">
              <div className="col-md-3">
                <span
                  onClick={() => {
                    setPage("pioneer-partners");
                  }}
                  className={page === "pioneer-partners" ? "active-btn" : ""}
                >
                  Pioneer Partners
                </span>
              </div>
              <div className="col-md-3">
                <span
                  onClick={() => {
                    setPage("products");
                  }}
                  className={page === "products" ? "active-btn" : ""}
                >
                  Products & Services
                </span>
              </div>
              <div className="col-md-8 text-right">
                <i class="fa fa-filter" aria-hidden="true"></i>
              </div>
            </div>
          </CardBox>
        </section>
        {page === "pioneer-partners" && (
          <section className="mt-3 partners-table">
            <CardBox>
              <div className="info-cont d-flex row">
                <div className="info-list col-md-6">
                  <Table responsive hover size="md">
                    <tbody>
                      {data?.partners.map((pioneer_partner, i) => (
                        <tr key={i}>
                          <td
                            className="partner-text"
                            colSpan="4"
                            onClick={() => viewPioneerPartner(pioneer_partner)}
                          >
                            <img alt="" width="23%" src={pioneer_partner.logo} />{" "}
                            <span>{pioneer_partner.name}</span>
                          </td>
                          <td className="text-right">
                            <div>
                              <img
                                src={pioneer_partner.category === "PLATINUM" ? platinum : gold}
                                width="20%"
                                alt="category"
                              />
                              <p>{pioneer_partner.category}</p>
                            </div>
                          </td>
                          <td>
                            <button
                              onClick={() => editPioneerPartner(pioneer_partner)}
                              className="btn btn-sm btn-secondary"
                            >
                              <small>edit</small>
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => handleDeletePioneerPartner(pioneer_partner.id)}
                              className="btn btn-sm btn-danger"
                            >
                              <small>delete</small>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="info-details col-md-6 mt-3">
                  {partnerInfo.name === "" ? (
                    <h4>Select a partner to view details</h4>
                  ) : (
                    <>
                      <h4>{partnerInfo.name}</h4>
                      <hr />
                      <img alt="" width="40%" src={partnerInfo.logo} />
                      <p>
                        <a href={partnerInfo.web_url}>{partnerInfo.web_url}</a>
                      </p>
                      <p>{partnerInfo.category}</p>
                      <p>
                        <b>Message to Lagosians:</b>
                      </p>
                      <p>{partnerInfo.message}</p>
                      <p>
                        <b>Environmental Sustainability Effort:</b>
                      </p>
                      <ul>
                        {splitEnvironmentalEfforts(partnerInfo.environmental_efforts).map(
                          (effort, i) => (
                            <li key={i}>{effort}</li>
                          )
                        )}
                      </ul>
                      <p>
                        <b>Products and Services:</b>
                      </p>
                      {partnerInfo?.partner_products.map((product, i) => (
                        <div key={i} className="product-preview-item">
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => switchToViewProduct(product)}
                          >
                            <small>{product.name}</small>
                          </button>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div>
                <DialogBox
                  open={open}
                  close={() => setOpen(false)}
                  buttonTitle={
                    <button className="btn btn-success" onClick={() => setOpen(true)}>
                      New
                    </button>
                  }
                  buttonOk={
                    <button className="btn btn-dark btn-sm" onClick={onPostPioneerPartner}>
                      {isLoading && <Spinner animation="border" className="ml-2" size="sm" />} Add
                      Partner
                    </button>
                  }
                >
                  <form>
                    {form.error && <small className="text-danger mb-4">{form.message}</small>}
                    {!form.error && <small className="text-success mb-2">{form.message}</small>}
                    <div className="avatar-cont">
                      <img id="output" width="150px" height="150px" />
                    </div>
                    <div className="form-group">
                      <input
                        id="output"
                        type="file"
                        className="form-control"
                        onChange={uploadMultipleFiles}
                        multiple
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput1"
                        placeholder="partner name"
                        value={form.name}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput2"
                        placeholder="web url"
                        value={form.web_url}
                        onChange={(e) => setForm({ ...form, web_url: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput3"
                        placeholder="message to Lagosians"
                        value={form.messageToLagos}
                        onChange={(e) => setForm({ ...form, messageToLagos: e.target.value })}
                      />
                    </div>
                    <div
                      className="form-group"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Delimit individual efforts using ';'"
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput4"
                        placeholder="environmental efforts"
                        value={form.environmental_efforts}
                        onChange={(e) =>
                          setForm({ ...form, environmental_efforts: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group mb-3">
                      <select
                        className="custom-select form-control form-control-sm "
                        id="inputGroupSelect01"
                        value={form.category}
                        onChange={(e) => setForm({ ...form, category: e.target.value })}
                      >
                        <option selected>select category</option>
                        <option value="GOLD">GOLD</option>
                        <option value="SILVER">SILVER</option>
                        <option value="PLATINUM">PLATINUM</option>
                      </select>
                    </div>
                  </form>
                </DialogBox>
                <DialogBox
                  open={openEdit}
                  close={() => setOpenEdit(false)}
                  buttonOk={
                    <button className="btn btn-dark btn-sm" onClick={onEditPioneerPartner}>
                      {editLoad && <Spinner animation="border" className="ml-2" size="sm" />} Edit
                      pioneer partner
                    </button>
                  }
                >
                  <form>
                    {form.error && <small className="text-danger mb-4">{form.message}</small>}
                    {!form.error && <small className="text-success mb-2">{form.message}</small>}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput1"
                        placeholder="pioneer partner name"
                        value={form.name}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput2"
                        placeholder="web url"
                        value={form.web_url}
                        onChange={(e) => setForm({ ...form, web_url: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput3"
                        placeholder="message to Lagosians"
                        value={form.messageToLagos}
                        onChange={(e) => setForm({ ...form, messageToLagos: e.target.value })}
                      />
                    </div>
                    <div
                      className="form-group"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Delimit individual efforts using ';'"
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput4"
                        placeholder="environmental efforts"
                        value={form.environmental_efforts}
                        onChange={(e) =>
                          setForm({ ...form, environmental_efforts: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group mb-3">
                      <select
                        className="custom-select form-control form-control-sm "
                        id="inputGroupSelect01"
                        value={form.category}
                        onChange={(e) => setForm({ ...form, category: e.target.value })}
                      >
                        <option selected>select category</option>
                        <option value="GOLD">GOLD</option>
                        <option value="SILVER">SILVER</option>
                        <option value="PLATINUM">PLATINUM</option>
                      </select>
                    </div>
                  </form>
                </DialogBox>
              </div>
            </CardBox>
          </section>
        )}
        {page === "products" && (
          <section className="mt-3 partners-table">
            <CardBox>
              <div className="info-cont d-flex row">
                <div className="info-list col-md-6">
                  <Table responsive hover size="md">
                    <tbody>
                      {productsData?.products.map((product, i) => (
                        <tr key={i}>
                          <td className="product-text" 
                            colSpan="4" onClick={() => viewProduct(product)}>
                            <img alt="" width="15%" src={product.media_url} />{" "}
                            <span>{product.name}</span>
                          </td>
                          <td>
                            <button
                              onClick={() => editProduct(product)}
                              className="btn btn-sm btn-secondary"
                            >
                              <small>edit</small>
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => handleDeleteProduct(product.id)}
                              className="btn btn-sm btn-danger"
                            >
                              <small>delete</small>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="info-details col-md-6 mt-3">
                  {productInfo.name === "" ? (
                    <h4>Select a product/service to view details</h4>
                  ) : (
                    <>
                      <h4>{productInfo.name}</h4>
                      <hr />
                      <img alt="" width="40%" src={productInfo.media_url} />
                      <p>{productInfo.description}</p>
                    </>
                  )}
                </div>
              </div>
              <div>
                <DialogBox
                  open={openProduct}
                  close={() => setOpenProduct(false)}
                  buttonTitle={
                    <button className="btn btn-success" onClick={() => setOpenProduct(true)}>
                      New
                    </button>
                  }
                  buttonOk={
                    <button className="btn btn-dark btn-sm" onClick={onPostProduct}>
                      {loadingProducts && <Spinner animation="border" className="ml-2" size="sm" />}{" "}
                      Add Product
                    </button>
                  }
                >
                  <form>
                    {form.error && <small className="text-danger mb-4">{form.message}</small>}
                    {!form.error && <small className="text-success mb-2">{form.message}</small>}
                    <div className="avatar-cont">
                      <img id="output" width="150px" height="150px" />
                    </div>
                    <div className="form-group">
                      <input
                        id="output"
                        type="file"
                        className="form-control"
                        onChange={uploadMultipleFiles}
                        multiple
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput1"
                        placeholder="product/service name"
                        value={form.name}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput2"
                        placeholder="description"
                        value={form.description}
                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <select
                        className="custom-select form-control form-control-sm "
                        id="inputGroupSelect01"
                        value={form.partner_id}
                        onChange={(e) => setForm({ ...form, partner_id: e.target.value })}
                      >
                        <option selected>select partner</option>
                        {data?.partners.map((partner, i) => (
                          <option key={i} value={partner.id}>
                            {partner.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                </DialogBox>
                <DialogBox
                  open={openEditProduct}
                  close={() => setOpenEditProduct(false)}
                  buttonOk={
                    <button className="btn btn-dark btn-sm" onClick={onEditProduct}>
                      {productEditLoading && <Spinner animation="border" className="ml-2" size="sm" />} Edit
                    </button>
                  }
                >
                  <form>
                    {form.error && <small className="text-danger mb-4">{form.message}</small>}
                    {!form.error && <small className="text-success mb-2">{form.message}</small>}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput1"
                        placeholder="product/service name"
                        value={form.name}
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="exampleFormControlInput2"
                        placeholder="description"
                        value={form.description}
                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <select
                        className="custom-select form-control form-control-sm "
                        id="inputGroupSelect01"
                        value={form.partner_id}
                        onChange={(e) => setForm({ ...form, partner_id: e.target.value })}
                      >
                        <option selected>select partner</option>
                        {data?.partners.map((partner, i) => (
                          <option key={i} value={partner.id}>
                            {partner.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                </DialogBox>
              </div>
            </CardBox>
          </section>
        )}
      </div>
    </MainLayout>
  );
};

export default Pioneer_Partners;
