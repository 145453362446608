import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import gold from "../../images/gold.svg";
import platinum from "../../images/platinum.svg";

import { deletePartner, fetchPartner, postEditPartner, postPartner } from "../../queries/partner";
import { deleteAdvert, fetchAdvert, postAdvert } from "../../queries/advert";
import { useQuery, useMutation } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import swal from "sweetalert";

const Sponsors = (props) => {
  const [form, setForm] = useState({
    title: "",
    content: "",
    message: "",
    error: false,
  });
  const [page, setPage] = useState("sponsor");
  const [partnerInfo, setPartnerInfo] = useState({ name: "", url:"" });

  const [mutate, { isLoading }] = useMutation(postPartner);
  const [mutateEditPartner,{isLoading: editLoad}] = useMutation(postEditPartner);
  const [mutateAds, { isLoading:loadingAds }] = useMutation(postAdvert);
  const [mutateDeletePartner] = useMutation(deletePartner);
  const [mutateDeleteAds] = useMutation(deleteAdvert);

  const { data, refetch } = useQuery("partners", fetchPartner);
  const { data:adsData, refetch:refetchAds } = useQuery("adverts", fetchAdvert);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAds, setOpenAds] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        onDeleteAds(id);
      } else {
       // swal("Your imaginary file is safe!");
      }
    });
  }

  const handleDeletePartner = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        onDeletePartner(id);
      } else {
       // swal("Your imaginary file is safe!");
      }
    });

  }

  const onPostPartner = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newCat.message });
        refetch();
        swal("Created", message, "success")
        setOpen(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onPostAds = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateAds({ form });
      const { data: newAds, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetchAds();
        swal("Created", message, "success")
        setOpenAds(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onDeleteAds = async (id) => {
    try {
      const res = await mutateDeleteAds(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message,"","error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetchAds();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onDeletePartner = async (id) => {
    try {
      const res = await mutateDeletePartner(id);
      const { hasError, errors, message } = res.data;
      if (hasError) {
        //setForm({ ...form, error: hasError, message: errors.message });
        swal(errors.message,"","error");
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        swal("Deleted!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onEditPartner = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateEditPartner({ form });
      const {hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, question:"", answer:"" });
        refetch();
        setOpenEdit(false);
        swal(message,"","success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };  
  
  const editPartner = (part) => {
    setForm({
      ...form,
      name: part.name,
      category: part.category,
      id: part.id
    });
    setOpenEdit(true);
  };

  const viewPartner = (partner) => {
    setPartnerInfo(partner);
    handleClose();
  };

  const [file, setFile] = useState();
  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  return (
    <>
      <MainLayout>
        <div className="sponsors">
        <section className="infrac-nav">
            <CardBox>
              <div className="nav-infrac">
              <div className="col-md-2">
                  <span
                    onClick={() => {
                      setPage("sponsor");
                    }}
                    className={page === "sponsor" ? "active-btn" : ""}
                  >
                    Sponsors
                  </span>
                </div>
                <div className="col-md-2">
                  <span
                    onClick={() => {
                      setPage("ads");
                    }}
                    className={page === "ads" ? "active-btn" : ""}
                  >
                    Ads
                  </span>
                </div>
                <div className="col-md-8 text-right">
                  <i class="fa fa-filter" aria-hidden="true"></i>
                </div>
              </div>
            </CardBox>
          </section>

          {page === "sponsor" && (
            <section className="mt-3 sponsors-table">
              <CardBox>
                <div className="info-cont d-flex row">
                  <div className="info-list col-md-6">
                    <Table responsive hover size="md">
                      <tbody>
                        {data?.partners.map((partner, i) => {
                          return (
                            <tr key={i} >
                              <td className="sponsor-text" onClick={() => viewPartner(partner)}>
                                <img src={partner.logo} width="15%" alt="" />{" "}
                                <span>{partner.name}</span>
                              </td>

                              <td className="text-right">
                                <div>
                                  <img
                                    src={
                                      partner.category === "platinum"
                                        ? platinum 
                                        : gold
                                    }
                                    width="20%"
                                    alt=""
                                  />
                                  <p>{partner.category}</p>
                                </div>
                              </td>
                              <td><button onClick={() => editPartner(partner)} className="btn btn-sm btn-secondary"><small>edit</small></button></td>
                              <td>
                              
                                <button onClick={() => handleDeletePartner(partner.id)} className="btn btn-sm btn-danger"><small>delete</small></button></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <div className="info-details col-md-6 mt-3">
                    {partnerInfo.name === "" ? (
                      <h4 className="text-center p-5">Select a Partner</h4>
                    ) : (
                      <>
                        <h5 className="news-title">{partnerInfo.name}</h5>
                        <hr />
                        <img src={partnerInfo.logo} width="40%" alt="logo" />
                        <p>{partnerInfo.category}</p>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <DialogBox
                    open={open}
                    close={() => setOpen(false)}
                    buttonTitle={
                      <button
                        className="btn btn-success"
                        onClick={() => setOpen(true)}
                      >
                        New
                      </button>
                    }
                    buttonOk={
                      <button
                        className="btn btn-dark btn-sm"
                        onClick={onPostPartner}
                      >
                        {isLoading && (
                          <Spinner
                            animation="border"
                            className="ml-2"
                            size="sm"
                          />
                        )}{" "}
                        add partner
                      </button>
                    }
                  >
                    <form>
                      {form.error && (
                        <small className="text-danger mb-4">
                          {form.message}
                        </small>
                      )}
                      {!form.error && (
                        <small className="text-success mb-2">
                          {form.message}
                        </small>
                      )}
                      <div className="avatar-cont">
                        <img id="output" width="150px" height="150px" />
                      </div>
                      <div className="form-group">
                        <input
                          id="output"
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="partner name"
                          value={form.name}
                          onChange={(e) =>
                            setForm({ ...form, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group mb-3">
                        <select
                          className="custom-select form-control form-control-sm "
                          id="inputGroupSelect01"
                          value={form.category}
                          onChange={(e) =>
                            setForm({ ...form, category: e.target.value })
                          }
                        >
                          <option selected>select category</option>
                          <option value="GOLD">GOLD</option>
                          <option value="SILVER">SILVER</option>
                          <option value="PLATINUM">PLATINUM</option>
                        </select>
                      </div>
                  
                    </form>
                  </DialogBox>

                  <DialogBox
                    open={openEdit}
                    close={() => setOpenEdit(false)}
                
                    buttonOk={
                      <button
                        className="btn btn-dark btn-sm"
                        onClick={onEditPartner}
                      >
                        {editLoad && (
                          <Spinner
                            animation="border"
                            className="ml-2"
                            size="sm"
                          />
                        )}{" "}
                        edit partner
                      </button>
                    }
                  >
                    <form>
                      {form.error && (
                        <small className="text-danger mb-4">
                          {form.message}
                        </small>
                      )}
                      {!form.error && (
                        <small className="text-success mb-2">
                          {form.message}
                        </small>
                      )}
                      {/* <div className="avatar-cont">
                        <img id="output" width="150px" height="150px" />
                      </div>
                      <div className="form-group">
                        <input
                          id="output"
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                          required
                        />
                      </div> */}
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="partner name"
                          value={form.name}
                          onChange={(e) =>
                            setForm({ ...form, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group mb-3">
                        <select
                          className="custom-select form-control form-control-sm "
                          id="inputGroupSelect01"
                          value={form.category}
                          onChange={(e) =>
                            setForm({ ...form, category: e.target.value })
                          }
                        >
                          <option selected>select category</option>
                          <option value="GOLD">GOLD</option>
                          <option value="SILVER">SILVER</option>
                          <option value="PLATINUM">PLATINUM</option>
                        </select>
                      </div>
                  
                    </form>
                  </DialogBox>
                </div>
              </CardBox>
            </section>
          )}

          {page === "ads" && (
            <section className="mt-3 sponsors-table">
              <CardBox>
                <div className="info-cont d-flex row">
                  <div className="info-list col-md-6">
                    <Table responsive hover size="md">
                      <tbody>
                        {adsData?.adverts.map((adss, i) => {
                          return (
                            <tr key={i} >
                              <td className="sponsor-text" onClick={() => viewPartner(adss)}>
                                <img src={adss.image} width="50px" height="50px" alt="" />{" "}
                                
                              </td>
                              <td onClick={() => viewPartner(adss)}><span>{adss.url}</span></td>
                              <td>{dayjs(adss.createdAt).format('MMM D')
                              }</td>
                              <td><button onClick={() => handleDelete(adss.id)} className="btn btn-sm btn-danger"><small>delete</small></button></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <div className="info-details col-md-6">
                    {partnerInfo.url === "" ? (
                      <h4 className="text-center p-5">Select an Ads</h4>
                    ) : (
                      <>
                        <h5 className="news-title">{partnerInfo.url}</h5>
                        <hr />
                        <img src={partnerInfo.image} width="40%" height="250px" alt="logo" />
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <DialogBox
                    open={openAds}
                    close={() => setOpenAds(false)}
                    buttonTitle={
                      <button
                        className="btn btn-success"
                        onClick={() => setOpenAds(true)}
                      >
                        New
                      </button>
                    }
                    buttonOk={
                      <button
                        className="btn btn-dark btn-sm"
                        onClick={onPostAds}
                      >
                        {loadingAds && (
                          <Spinner
                            animation="border"
                            className="ml-2"
                            size="sm"
                          />
                        )}{" "}
                        add ads
                      </button>
                    }
                  >
                    <form>
                      {form.error && (
                        <small className="text-danger mb-4">
                          {form.message}
                        </small>
                      )}
                      {!form.error && (
                        <small className="text-success mb-2">
                          {form.message}
                        </small>
                      )}
                       <div className="form-group">
                        <input
                          id="output"
                          type="file"
                          className="form-control"
                          onChange={uploadMultipleFiles}
                          multiple
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="exampleFormControlInput1"
                          placeholder="advert url"
                          value={form.url}
                          onChange={(e) =>
                            setForm({ ...form, url: e.target.value })
                          }
                        />
                      </div>
                      
                    </form>
                  </DialogBox>
                </div>
              </CardBox>
            </section>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default Sponsors;
