import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";


export const fetchWeather = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.weather}`, {
    headers
  });
  return res.data.data;
};
