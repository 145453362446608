import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./styles.scss";

export default function DialogBox(props) {
    const { children, title, buttonTitle, buttonOk, open, close } = props;
   
   
  return (
    <>
    <span> {buttonTitle} </span>
      <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          {buttonOk}
        </DialogActions>
      </Dialog>
    </>
  );
}
