import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const postPioneerPartner = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const fd = new FormData();
  fd.append("name", form.name);
  fd.append("image", form.image, "Image 14@2x.png");
  fd.append("category", form.category);
  fd.append("web_url", form.web_url);
  fd.append("message", form.messageToLagos);
  fd.append("environmental_efforts", form.environmental_efforts);

  const res = await Axios.post(API_CONSTANT_MAP.pioneer_partner.create, fd, {
    headers,
  });
  return res;
};

export const postEditPioneerPartner = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': "application/json",
  };
  const rawData = {
    ...(form.name && { name: form.name }),
    ...(form.category && { category: form.category }),
    ...(form.web_url && { web_url: form.web_url }),
    ...(form.message && { message: form.message }),
    ...(form.environmental_efforts && { environmental_efforts: form.environmental_efforts }),
  };

  const res = await Axios.put(
    `${API_CONSTANT_MAP.pioneer_partner.edit}${form.id}`,
    JSON.stringify(rawData),
    {
      headers,
    }
  );
  return res;
};

export const fetchPioneerPartner = async ({ params }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.pioneer_partner.fetchAll}${params || "ALL"}`, {
    headers,
  });
  return res.data.data;
};

export const deletePioneerPartner = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.pioneer_partner.delete}${id}`, {
    headers,
  });
  return res;
};
