import React from "react";
import citi from "../images/citi.svg";

const CitiMonitor = (props) => {
  return (
    <section style={styles.container}>
      <img src={citi} alt="citi" width="20%" />
    </section>
  );
};

const styles = {
  container:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'100vh'
  }
}

export default CitiMonitor;
