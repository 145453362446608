import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";


export const postFaq = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const data = {
    question: form.question,
    answer: form.answer,
  };

  const res = await Axios.post(API_CONSTANT_MAP.faq, data, {
    headers
  });
  return res;
};


export const postEditFaq = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const data = {
    question: form.question,
    answer: form.answer,
  };

  const res = await Axios.put(`${API_CONSTANT_MAP.faqAdmin}/${form.id}`, data, {
    headers
  });
  return res;
};

export const fetchFaq = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(API_CONSTANT_MAP.faq, {
    headers
  });
  return res.data.data;
};

export const deleteFaq = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.faqAdmin}/${id}`, {
    headers
  });
  return res;
};