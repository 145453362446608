import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";


export const postNews = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const fd = new FormData();
  fd.append("title", form.title);
  fd.append("image", form.image, "Image 14@2x.png");
  fd.append("news", form.news);

  const res = await Axios.post(API_CONSTANT_MAP.news, fd, {
    headers
  });
  return res;
};

export const postEditNews = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const fd = new FormData();
  fd.append("title", form.title);
  //fd.append("image", form.image, "Image 14@2x.png");
  fd.append("news", form.news);

  const res = await Axios.put(`${API_CONSTANT_MAP.newsAdmin}/${form.id}`, fd, {
    headers
  });
  return res;
};


export const fetchNews = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(API_CONSTANT_MAP.news, {
    headers
  });
  return res.data.data;
};


export const deleteNews = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.newsAdmin}/${id}`, {
    headers
  });
  return res;
};