import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const postMarket = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    name: form.name,
    about: form.about,
  };

  const res = await Axios.post(API_CONSTANT_MAP.market, data, {
    headers,
  });
  return res;
};

export const postEditMarket = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    name: form.name,
    about: form.about,
    id: form.id,
  };

  const res = await Axios.put(API_CONSTANT_MAP.market, data, {
    headers,
  });
  return res;
};

export const fetchMarket = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.market}`, {
    headers,
  });
  return res.data.data;
};

export const fetchMarketById = async (params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.market}/${params}`, {
    headers,
  });
  return res;
};

export const deleteMarket = async (params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  console.log(params);
  const res = await Axios.delete(`${API_CONSTANT_MAP.market}/${params}`, {
    headers,
  });
  return res;
};
