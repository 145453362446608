import React, { useState, useEffect, useCallback } from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import dayjs from "dayjs";
import { fetchLgaById } from "../../queries/lga";
import { fetchMarketById } from "../../queries/market";
import { fetchCategoryById } from "../../queries/category";
import truncate from "../../helpers/truncate";
const InfractionsMapComponent = (props) => {
  const history = useHistory();
  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const { infractions, updateParent } = props;
  const [center, setCenter] = useState({
    lat: 6.509282455855798,
    lng: 3.3570037014549525,
  });

  const [isOpen, setOpen] = useState(false);
  const [currInfraction, setCurrInfraction] = useState(null);
  const [marketInfo, setMarketInfo] = useState("");
  const [lgaInfo, setLgaInfo] = useState("");
  const [categoryInfo, setCategoryInfo] = useState("");

  const [mutateMarket] = useMutation(fetchMarketById);
  const [mutateLga] = useMutation(fetchLgaById);
  const [mutateCategory] = useMutation(fetchCategoryById);

  const toggleOpen = async (infraction) => {
    if (infraction) {
      const { lga_id, market_id, category_id } = infraction;
      setCurrInfraction(infraction);
      setOpen(true);
      const [marketRes, lgaRes, categoryRes] = await Promise.all([
        mutateMarket(market_id),
        mutateLga(lga_id),
        mutateCategory(category_id),
      ]);
      const { data: marketData, hasError: marketHasError } = marketRes?.data;
      const { data: lgaData, hasError: lgaHasError } = lgaRes?.data;
      const { data: categoryData, hasError: categoryHasError } = categoryRes?.data;
      if (!marketHasError) setMarketInfo(marketData.market.name);
      if (!lgaHasError) setLgaInfo(lgaData.lga.name);
      if (!categoryHasError) setCategoryInfo(categoryData.category.name);
    } else {
      setCurrInfraction(null);
      setOpen(false);
      setMarketInfo("");
      setLgaInfo("");
    }
  };
  useEffect(() => {
    if (isOpen && currInfraction) {
      setCenter({ lat: currInfraction.latitude, lng: currInfraction.longitude });
    }
  }, [isOpen, currInfraction]);

  let statusColor = {
    waiting: "#D8D8D8",
    ongoing: "#007bff",
    completed: "#4ABA4C",
    "on hold": "#ffc402",
    due: "#CF0A1E",
  };

  const getStatusIndicator = (status) => {
    let indicate = statusColor[status];
    let widthStatus = status === "completed" ? "100%" : "50%";
    return { indicate, widthStatus };
  };

  return (
    <>
      <GoogleMap
        onLoad={onLoad}
        // ref={map}
        zoom={props.zoom}
        center={center}
        mapContainerClassName="map-container"
        onBoundsChanged={() => updateParent(map)}
      >
        {infractions?.length > 0 &&
          infractions?.map(
            (infraction, i) =>
              infraction.latitude &&
              props.isMarkerShown && (
                <Marker
                  onClick={() => toggleOpen(infraction)}
                  key={i}
                  position={{ lat: infraction.latitude, lng: infraction.longitude }}
                >
                  {isOpen && currInfraction?.id === infraction.id && (
                    <InfoWindow onCloseClick={() => toggleOpen()}>
                      <div className="info-window">
                        <div className="infraction-info">
                          <div className="details-header">
                            <div className="infraction-image">
                              <img alt="" src={infraction.media_url} />
                            </div>
                            <div className="infraction-location">
                              <p className="location">{currInfraction.location}</p>
                            </div>
                          </div>
                          <div className="infraction-details">
                            <p>
                              <b>Code:</b> {currInfraction.code}
                            </p>
                            <p>
                              <b>Status:</b> {currInfraction.status}{" "}
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                                style={{
                                  color: getStatusIndicator(currInfraction.status).indicate,
                                }}
                              />
                            </p>
                            <p>
                              <b>Reported on:</b>{" "}
                              {dayjs(currInfraction.createdAt).format("MMMM D, YYYY h:mm A")}
                            </p>
                            <p>
                              <b> {currInfraction.assigned ? "Assigned " : "Not Assigned"}</b>
                            </p>
                            <p>
                              <b>Summary:</b> {truncate(currInfraction.summary, 30)}
                            </p>
                            <p>
                              <b>Market: </b>
                              {marketInfo === "" ? "none " : marketInfo}
                            </p>
                            <p>
                              <b>LGA: </b>
                              {lgaInfo === "" ? "none " : lgaInfo}
                            </p>
                            <p>
                              <b>CATEGORY: </b>
                              {categoryInfo === "" ? "none " : categoryInfo}
                            </p>
                            <div className="progress mt-2 mb-2" style={{ height: "8px" }}>
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: getStatusIndicator(currInfraction.status).widthStatus,
                                  backgroundColor: getStatusIndicator(currInfraction.status)
                                    .indicate,
                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <button
                              className="btn btn-success btn-sm btn-secondary"
                              onClick={() => history.push(`/infraction/${infraction.id}`)}
                            >
                              <small>
                                View infraction <i className="fa fa-eye" aria-hidden="true" />
                              </small>
                            </button>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )
          )}
      </GoogleMap>
    </>
  );
};

export default InfractionsMapComponent;
