import React, { useState } from "react";
import "./styles.scss";
import split_location from "../../helpers/split-address";
import truncate from "../../helpers/truncate";
import dayjs from "dayjs";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useMutation, useQuery } from "react-query";
import { updateInfractionAgency, assignInfraction } from "../../queries/infractions";
import { queryCache } from "react-query";
import DialogBox from "../DialogBox/DialogBox";
import { Spinner } from "react-bootstrap";
import { fetchAgency } from "../../queries/agency";
import swal from "sweetalert";

export default function CardInfractionAgency(props) {
  const { status, navigate, infraction } = props;

  const [form, setForm] = useState({
    status: "",
    id: infraction.id,
    agency: "",
    message: "",
    error: false,
  });

  const { isLoading: agencyLoad, data: agencyData } = useQuery(
    "agencies",
    fetchAgency
  );

  const [mutate, { isLoading }] = useMutation(updateInfractionAgency);
  const [mutateAssign, { isLoading: assignLoading }] = useMutation(
    assignInfraction
  );

  const [open, setOpen] = React.useState(false);
  const [openAssign, setOpenAssign] = React.useState(false);

  const onUpdateInfraction = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      console.log(res);
      const { data: newInfraction, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newInfraction.message });
        queryCache.invalidateQueries("infractions");
        setOpen(false);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onAssignInfraction = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateAssign({ form });
      console.log(res);
      const { data: newInfraction, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: newInfraction.message });
        queryCache.invalidateQueries("infractions");
        setOpenAssign(false);
        swal("Agency assigned", message, "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  const onUpdate = () => {
    setOpen(true);
    handleClose();
  };

  const onView = () => {
    navigate();
    handleClose();
  };

  const onAssign = () => {
    setOpenAssign(true);
    handleClose();
  };

  let statusColor = {
    waiting: "#D8D8D8",
    ongoing: "#007bff",
    completed: "#4ABA4C",
    'on hold': "#ffc402",
    due: "#CF0A1E",
  };

  let indicate = statusColor[status];
  let widthStatus = status === "completed" ? "100%" : "50%";

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <section className="card-infraction">
        <div className="card-box">
          <div className="d-flex justify-content-between">
            <strong className="title-infra">
              {split_location(infraction.location)}{" "}
              <i
                className="fa fa-circle"
                style={{ color: indicate }}
                aria-hidden="true"
              ></i>
            </strong>
 
            <i
              className="fa fa-ellipsis-h icon-menu"
              aria-hidden="true"
              onClick={handleClick}
            ></i>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onView}>
                <small>view</small>
              </MenuItem>
              <MenuItem onClick={onUpdate}>
                <small>change</small>
              </MenuItem>
              {/* <MenuItem onClick={onAssign}>
                <small>assign</small>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <small>delete</small>
              </MenuItem> */}
            </Menu>
          </div>
          <small className="infra-location">{truncate(infraction.location, 40)}</small>
          <p className="title-summary">{truncate(infraction.summary, 30)}</p>
          <div className="infra-info-text">
          <small>{infraction.code}</small> <br />
          <small>
            Reported on{" "}
            {dayjs(infraction.createdAt).format("MMMM D, YYYY h:mm A")}
          </small>{" "}

          </div>
          <br />
          <small>
            <strong>status</strong> {infraction.status}
          </small>
          <div className="progress mt-2 mb-2" style={{ height: "8px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: widthStatus, backgroundColor: indicate }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div className="d-flex justify-content-between">
            <div
              className="badge p-2"
              style={{ backgroundColor: indicate, color: "#fff" }}
            >
              <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
              {dayjs(infraction.createdAt).format("MMM D,")}
            </div>
            {/* <small  className="agency-assigned">{ infraction.assigned_agency
                ? infraction.assignedagencies[0].agency.acronym
                : "Not assigned"}  <i class="fa fa-line-chart" aria-hidden="true"></i></small> */}
            {/* <span className="agency-assigned">
              {infraction.assigned_agency
                ? infraction.assignedagencies[0].agency.name
                : "Not assigned"}{" "}
              <i class="fa fa-line-chart" aria-hidden="true"></i>
            </span> */}
          </div>
        </div>
        <DialogBox
          open={open}
          close={() => setOpen(false)}
          buttonOk={
            <button
              className="btn btn-dark btn-sm"
              onClick={onUpdateInfraction}
            >
              {isLoading && (
                <Spinner animation="border" className="ml-2" size="sm" />
              )}{" "}
              change
            </button>
          }
        >
          <form>
            {form.error && (
              <small className="text-danger mb-4">{form.message}</small>
            )}
            {!form.error && (
              <small className="text-success mb-2">{form.message}</small>
            )}
            <div className="input-group mb-3">
              <select
                className="custom-select product-select"
                id="inputGroupSelect01"
                value={form.status}
                onChange={(e) => setForm({ ...form, status: e.target.value })}
              >
                <option selected>select status</option>
                <option>waiting</option>
                <option>ongoing</option>
                <option>on hold</option>
                <option>due</option>
                <option>completed</option>
              </select>
            </div>
           
          </form>
        </DialogBox>

        <DialogBox
          open={openAssign}
          close={() => setOpenAssign(false)}
          buttonOk={
            <button
              className="btn btn-dark btn-sm"
              onClick={onAssignInfraction}
            >
              {assignLoading && (
                <Spinner animation="border" className="ml-2" size="sm" />
              )}{" "}
              assign
            </button>
          }
        >
          <form>
            {form.error && (
              <small className="text-danger mb-4">{form.message}</small>
            )}
            {!form.error && (
              <small className="text-success mb-2">{form.message}</small>
            )}
            <div className="input-group mb-3">
              <select
                className="custom-select product-select"
                id="inputGroupSelect01"
                value={form.agency}
                onChange={(e) => setForm({ ...form, agency: e.target.value })}
              >
                <option selected>select agency</option>
                {agencyData?.agencies.map((agency, i) => {
                  return (
                    <option value={agency.id} key={i}>
                      {agency.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="start-date">Completion time</label>
              <input
                type="date"
                className="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="Start date"
                value={form.estoc}
                onChange={(e) => setForm({ ...form, estoc: e.target.value })}
              />
            </div>
          </form>
        </DialogBox>
      </section>
    </>
  );
}
