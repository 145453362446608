import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const postCategory = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    name: form.name,
    about: form.about,
  };

  const res = await Axios.post(API_CONSTANT_MAP.categories, data, {
    headers,
  });
  return res;
};

export const fetchCategory = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.categories}`, {
    headers,
  });
  return res.data.data;
};

export const fetchCategoryById = async (params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.categories}/${params}`, {
    headers,
  });
  return res;
};

export const deleteCategory = async (params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  console.log(params);
  const res = await Axios.delete(`${API_CONSTANT_MAP.categories}/${params}`, {
    headers,
  });
  return res;
};
