import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";


export const postEvent = async ({
  form
}) => {
  const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};

  const data = {
    name: form.name,
    startDate: form.startDate,
    endDate: form.endDate,
    venue: form.venue,
    description: form.description
  };

  const res = await Axios.post(API_CONSTANT_MAP.event, data, {
    headers
  });
  return res;
};


export const fetchEvents = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  
  const res = await Axios.get(`${API_CONSTANT_MAP.event}`, {
    headers
  });

  return res.data.data.events.map((newevent,i) => {
    return{
      start:  new Date(newevent.startDate) ,
      end: new Date(newevent.endDate),
      title:newevent.name,
      venue:newevent.venue,
    }
  });

//  return res.data.data;
};

// export const fetchCategoryById = async (params) => {
//   const token = Auth.getToken();
//   const headers = {
//     Authorization: `Bearer ${token}`
//   };
  
//   const res = await Axios.get(`${API_CONSTANT_MAP.categories}/${params}`, {
//     headers
//   });
//   return res.data.data;
// };

// export const deleteCategory = async (params) => {
//   const token = Auth.getToken();
//   const headers = {
//     Authorization: `Bearer ${token}`
//   };
//   console.log(params);
//   const res = await Axios.delete(`${API_CONSTANT_MAP.categories}/${params}`, {
//     headers
//   });
//   return res;
//};
