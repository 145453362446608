const routes = {
  SIGNIN: "/",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  MOBILE_REDIRECT: "/mobile-redirect",
  DASHBOARD: "/dashboard",
  INFRACTIONS: "/infractions",
  INFRACTIONS_MAP: "/infractions-map",
  INFRACTION: "/infraction/:infraction",
  DASHBOARD_AGENCY: "/dashboard-agency",
  INFRACTIONS_AGENCY: "/infractions-agency",
  CATEGORIES: "/categories",
  AGENCIES: "/agencies",
  NEWS: "/news",
  NOTIFICATION: "/notifications",
  SPONSORS: "/sponsors",
  PIONEER_PARTNERS: "/pioneer-partners",
  FAQ: "/faq",
  LGA: "/lga",
  SETTINGS: "/settings",
  SETTINGS_AGENCY: "/settings-agency",
  AUDIT_LOGS: "/audit-logs",
  USER_ACTIVITY: "/user-activity/:user",
  EVENTS: "/events",
  SEARCH: "/search",
  MARKET: "/market",
  ERROR_PAGE: "*",
};

export default routes;
