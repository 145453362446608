import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const fetchSuspensionSettings = async (key) => {
  const res = await Axios.get(`${API_CONSTANT_MAP.admin.suspension_settings}`);
  return res.data.data;
};

export const updateSuspensionSettings = async ({ user_role, is_suspended }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const res = await Axios.put(
    `${API_CONSTANT_MAP.admin.suspend_user}group/${
      user_role || "user"
    }/${is_suspended}`,
    {
      headers,
    }
  );
  return res.data;
};
