import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import {
  fetchLogs,
  fetchSettings,
  updateSettings,
} from "../../queries/audit_logs";
import { useQuery, useMutation, usePaginatedQuery } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import dayjs from "dayjs";
import { isEmpty } from "../../helpers/is_empty";

const AuditLogs = (props) => {
  const history = useHistory();

  const [tab, setTab] = useState("logs");
  const [filter, setFilter] = useState("all");
  const [pageCount, setPageCount] = useState(12);

  const [mutateSettings, { isLoading: settingsUpdating }] =
    useMutation(updateSettings);

  const {
    resolvedData: auditTrail,
    refetch: refetchLogs,
    isLoading: auditTrailLoading,
    isFetching: auditTrailFetching,
  } = usePaginatedQuery(["audit_trail", filter, pageCount], fetchLogs);
  const {
    isLoading: settingsLoading,
    data: logsSettings,
    refetch: refetchSettings,
  } = useQuery("fetch_settings", fetchSettings);

  const viewMore = () => {
    setPageCount(pageCount + 6);
  };

  const updateSettingHandler = async (user_role, is_logged) => {
    try {
      const res = await mutateSettings({ user_role, is_logged: !is_logged });
      console.log("res", res);
      const { data: newCat, hasError, errors, message } = res;
      if (hasError) {
        NotificationManager.error("Failed", errors.message);
      } else {
        NotificationManager.success("Success", message);
        refetchSettings();
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
      NotificationManager.error("Failed", error);
    }
  };

  const userNameClickHandler = (user_id) => {
    if (user_id) history.push(`/user-activity/${user_id}`);
  };

  return (
    <>
      <MainLayout>
        <NotificationContainer />
        <div className="audit-trail">
          <section className="pre-header">
            <CardBox>
              <div className="menu-header">
                <div className="col-md-10 tab-nav">
                  <div
                    className={tab === "logs" ? "tab active-tab" : "tab"}
                    onClick={() => setTab("logs")}
                  >
                    <p>
                      Audit Trail{" "}
                      {(auditTrailLoading || auditTrailFetching) && (
                        <Spinner animation="border" size="sm" />
                      )}
                    </p>
                  </div>
                  <div
                    className={tab === "settings" ? "tab active-tab" : "tab"}
                    onClick={() => setTab("settings")}
                  >
                    <p>
                      Audit Trail Settings{" "}
                      {(settingsUpdating || settingsLoading) && (
                        <Spinner animation="border" size="sm" />
                      )}
                    </p>
                  </div>
                </div>
                {tab === "logs" && (
                  <div className="col-md-2 ml-auto text-right log-filter">
                    <select
                      className="custom-select form-control form-control-sm "
                      id="inputGroupSelect01"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <option value="all">all</option>
                      <option value="user">user</option>
                      <option value="staff">staff</option>
                      <option value="admin">admin</option>
                      <option value="agency">agency</option>
                      <option value="superAdmin">super admin</option>
                    </select>
                  </div>
                )}
              </div>
            </CardBox>
          </section>
          {tab === "logs" && (
            <div className="mt-3 logs-table">
              <CardBox>
                <Table responsive hover size="md">
                  <thead className="logs-table-head">
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Role</th>
                      {/* <th>Agency</th> */}
                      <th>Description</th>
                      <th>Logged at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {auditTrail?.logs.length > 0 ? (
                      auditTrail?.logs.map((log, i) => {
                        return (
                          <tr key={i}>
                            {isEmpty(log.data.user) ? (
                              <td colSpan={3}>
                                <small>No user information</small>
                              </td>
                            ) : (
                              <>
                                <td>
                                  <img
                                    src={log.data.user.profile_picture}
                                    id="output"
                                    width="50px"
                                    height="50px"
                                    className="img-avatar"
                                  />
                                </td>
                                <td
                                  className="username"
                                  onClick={() =>
                                    userNameClickHandler(log.data.user.id)
                                  }
                                  title={
                                    "view " +
                                    log.data.user.first_name +
                                    "'s profile"
                                  }
                                >
                                  {log.data.user.first_name}{" "}
                                  {log.data.user.last_name}
                                </td>
                                <td>{log.data.user.user_role}</td>
                              </>
                            )}
                            <td>
                              <small>{log.data.description}</small>
                            </td>
                            {/* <td>{log.data.user.agency_id ? log.data.user.agency_id : "no agency"}</td> */}
                            <td>
                              <small>
                                {" "}
                                {dayjs(log.data.createdAt).format(
                                  "MMMM D, YYYY h:mm A"
                                )}
                              </small>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <p className="no-data">
                            No logs for -<span>{filter}</span>- category
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <button
                  className="btn btn-sm btn btn-outline-secondary"
                  onClick={viewMore}
                >
                  next
                </button>
              </CardBox>
            </div>
          )}
          {tab === "settings" && (
            <div className="mt-3 settings-table">
              <CardBox>
                <Table responsive hover size="md">
                  <thead className="settings-table-head">
                    <tr>
                      <th>Role</th>
                      <th>Logging</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logsSettings?.log_settings.map((setting, i) => {
                      return (
                        <tr key={i}>
                          <td>{setting.user_role}</td>
                          <td
                            className={
                              setting.is_logged
                                ? "toggled-on toggle-td"
                                : "toggle-td"
                            }
                            onClick={() =>
                              updateSettingHandler(
                                setting.user_role,
                                setting.is_logged
                              )
                            }
                          >
                            {setting.is_logged ? (
                              <i
                                className="fa fa-toggle-on"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-toggle-off"
                                aria-hidden="true"
                              ></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBox>
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default AuditLogs;
