import React, { Fragment, useState, useRef } from "react";
import "./styles.scss";
import { Link, useHistory, NavLink } from "react-router-dom";
import { Auth } from "../../config";
import citi2 from "../../images/citi2.svg";
import NavBar from "../NavBar/NavBar";
import { useEffect } from "react";

const MainLayout = (props) => {
  const history = useHistory();
  const sideNav = useRef(null);
  const page = useRef(null);
  const [user, setUser] = useState({});
  const [tog, setTog] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setUser(Auth.getUser());
  }, []);

  const showSideNav = () => {
    if (sideNav.current && page.current) {
      sideNav.current.style.width = "250px";
      page.current.style.marginLeft = "250px";
      setTog(false);
    }
  };

  const hideSideNav = () => {
    if (window.innerWidth < 768) {
      if (sideNav.current && page.current) {
        sideNav.current.style.width = "0px";
        page.current.style.marginLeft = "0px";
        setTog(true);
      }
    }
  };

  const handleLogout = () => {
    Auth.destroyToken();
    history.go("/");
  };

  const sidebar = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <i className="fa fa-bar-chart fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/dashboard-agency",
      name: "Dashboard",
      icon: <i className="fa fa-bar-chart fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym !== "MOEWR" ? true : false,
    },
    {
      path: "/infractions-agency",
      name: "Infractions",
      icon: <i className="fa fa-briefcase fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym !== "MOEWR" ? true : false,
    },
    {
      path: "/infractions",
      name: "Infractions",
      icon: <i className="fa fa-briefcase fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/infractions-map",
      name: "Infractions Map",
      icon: <i className="fa fa-briefcase fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/categories",
      name: "MOEWR Categories",
      icon: <i className="fa fa-cube fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/agencies",
      name: "Agencies",
      icon: <i className="fa fa-user fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/news",
      name: "News",
      icon: <i className="fa fa-newspaper-o fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/lga",
      name: "LGA",
      icon: <i className="fa fa-cube fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/market",
      name: "Markets",
      icon: <i className="fa fa-home fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/sponsors",
      name: "Sponsors & Ads",
      icon: <i className="fa fa-list fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" && user?.user_role === "superAdmin" ? true : false,
    },
    {
      path: "/pioneer-partners",
      name: "Partners",
      icon: <i className="fa fa-suitcase fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" && user?.user_role === "superAdmin" ? true : false,
    },
    {
      path: "/faq",
      name: "FAQs",
      icon: <i className="fa fa-question fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/events",
      name: "Calendar",
      icon: <i className="fa fa-calendar fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/settings",
      name: "Settings",
      icon: <i className="fa fa-cog fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/audit-logs",
      name: "Audit Logs",
      icon: <i className="fa fa-book fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym === "MOEWR" ? true : false,
    },
    {
      path: "/settings-agency",
      name: "Settings",
      icon: <i className="fa fa-cog fa-lg pr-3" aria-hidden="true"></i>,
      auth: user?.agency?.acronym !== "MOEWR" ? true : false,
    },
  ];

  return (
    <Fragment>
      <div className="main-cont">
        <div ref={sideNav} className="side">
          <div className="brand-cont">
            <img src={citi2} alt="citi" width="60%" />
          </div>
          <div className="side-links">
            {sidebar.map((nav) => {
              // console.log(nav.auth, nav.path);
              if (nav.auth) {
                return (
                  <NavLink key={nav.name} to={nav.path} className="list-group-item">
                    {nav.icon} {nav.name}
                  </NavLink>
                );
              }
            })}
          </div>
        </div>
        <div ref={page} className="page">
          <NavBar toggle={tog ? showSideNav : hideSideNav} logout={handleLogout} user={user} />
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

export default MainLayout;
