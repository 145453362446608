import React from "react";
import "./styles.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";

const MyMapComponent = (props) => {
  const { latitude, longitude } = props;
  return (
    <>
      <GoogleMap
        zoom={22}
        center={{ lat: latitude, lng: longitude }}
        mapContainerClassName="map-container"
      >
        {props.isMarkerShown && <Marker position={{ lat: latitude, lng: longitude }} />}
      </GoogleMap>
    </>
  );
};

export default MyMapComponent;
