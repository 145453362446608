import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import { Auth } from "../config";

export const fetchInfractions = async (key, params, count) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.infractions.view}${params || "all"}&pages=1&paginate=${count || 12}`);
  return res.data.data;
};
export const fetchInfractionsByCoordinates = async (key, params, coordinates) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const res = await Axios.get(
    `${API_CONSTANT_MAP.infractions.viewByCoordinates}${params || "all"}&longitude1=${coordinates.longitude1}&longitude2=${coordinates.longitude2}&latitude1=${coordinates.latitude1}&latitude2=${coordinates.latitude2}`,
    {
      headers,
    }
  );
  return res.data.data;
};

export const fetchInfraction = async (key, params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.admin.single}/${params}`, {
    headers,
  });
  return res.data.data;
};

export const fetchAgencyInfraction = async (key, params, count) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.agency_infraction}?${params || "all"}&pages=1&paginate=${count || 12}`, {
    headers,
  });
  return res.data.data;
};

export const fetchSearchInfraction = async (key, params) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.infraction_search}?search=${params}`, {
    headers,
  });
  return res.data.data;
};

export const fetchInfractionLogs = async (key, params, count) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.infraction_logs}/${params}?pages=1&paginate=${count || 8}`, {
    headers,
  });
  return res.data.data;
};

export const updateInfraction = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    infraction_id: form.id,
    status: form.status,
  };

  const res = await Axios.post(`${API_CONSTANT_MAP.infractions.update}`, data, {
    headers,
  });
  return res;
};

export const postComment = async ({ comment }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  console.log("comment sent", comment);
  const data = {
    infraction_id: comment.id,
    comment: comment.text,
  };

  const res = await Axios.post(`${API_CONSTANT_MAP.comment}`, data, {
    headers,
  });
  return res;
};

export const assignInfraction = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    infraction_id: form.id,
    agency_id: form.agency,
    estoc: form.estoc,
  };

  const res = await Axios.post(`${API_CONSTANT_MAP.infractions.assign}`, data, {
    headers,
  });
  return res;
};

export const updateInfractionAgency = async ({ form }) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const data = {
    infraction_id: form.id,
    status: form.status,
  };

  const res = await Axios.post(`${API_CONSTANT_MAP.infractions.updateAgency}`, data, {
    headers,
  });
  return res;
};

export const deleteInfraction = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.admin.delete_infraction}${id}`, {
    headers,
  });
  return res;
};
