import React, { useEffect, useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import gold from "../../images/gold.svg";
import platinum from "../../images/platinum.svg";

import { fetchAdminUser, fetchAllUser, postCreateUser, postUpdateUser } from "../../queries/user";
import { useQuery, useMutation, usePaginatedQuery } from "react-query";
import { Spinner, Table } from "react-bootstrap";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DialogBox from "../../components/DialogBox/DialogBox";
import dayjs from "dayjs";
import truncate from "../../helpers/truncate";
import { Auth } from "../../config";
import swal from "sweetalert";

import { fetchAgency } from "../../queries/agency";

const SettingsAgency = (props) => {
  const [form, setForm] = useState({
    content: "",
    message: "",
    error: false,
  });

  const [userform, setUserForm] = useState({
    content: "",
    message: "",
    error: false,
  });

  useEffect(() => {
    const user = Auth.getUser();
    setUserForm({
      ...userform,
      firstname: user.first_name,
      lastname: user.last_name,
      phone: user.phone,
      email: user.email,
      admin: user.user_role,
      agency_id: user.agency_id,
      agency_acronym: user.agency.acronym,
      user_role: user.user_role,
      profile_picture: user.profile_picture,
    });
  }, []);

  const [page, setPage] = useState("admin");
  const [file, setFile] = useState();
  const [userInfo, setuserInfo] = useState({ name: "" });
  const [pageCount, setPageCount] = useState(12);

  const [mutate, { isLoading }] = useMutation(postCreateUser);
  const [mutateAssign, { isLoading: assignLoading }] = useMutation(postUpdateUser);

  const { status: adminStatus, resolvedData: adminData, refetch } = usePaginatedQuery(["admin", pageCount], fetchAdminUser);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAssign, setOpenAssign] = React.useState(false);

  const { isLoading: agencyLoad, data: agencyData } = useQuery("agencies", fetchAgency);

  const onAssign = (email) => {
    setForm({ ...form, email: email });
    setOpenAssign(true);
  };

  const onAssignUser = async (e) => {
    e.preventDefault();
    try {
      const res = await mutateAssign({ form });
      const { data: newCat, hasError, errors, message } = res.data;
      if (hasError) {
        console.log(errors.message);
        setForm({ ...form, error: hasError, message: errors.message });
      } else {
        setForm({ ...form, error: hasError, message: message });
        refetch();
        setOpenAssign(false);
        console.log(newCat);
        swal("Assigned successfully", message, "success");
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  function uploadMultipleFiles(e) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    console.log(e.target.files[0]);

    // fileObj.push(e.target.files);
    // for (let i = 0; i < fileObj[0].length; i++) {
    //   fileArray.push(URL.createObjectURL(fileObj[0][i]));
    // }
    setFile(e.target.files[0]);
    setForm({ ...form, image: e.target.files[0] });
  }

  const viewMore = () => {
    setPageCount(pageCount + 6);
  };

  return (
    <>
      <MainLayout>
        <div className="settings">
          <section className="settings-nav">
            <CardBox>
              <div className="row">
                <div className="col-md-3">
                  <span
                    onClick={() => {
                      setPage("admin");
                    }}
                    className={page === "admin" ? "active-btn" : ""}
                  >
                    Admin roles
                  </span>
                </div>
                <div className="col-md-3">
                  <span
                    onClick={() => {
                      setPage("account");
                    }}
                    className={page === "account" ? "active-btn" : ""}
                  >
                    My Account
                  </span>
                </div>
                <div className="col-md-6 text-right">
                  <i class="fa fa-filter" aria-hidden="true"></i>
                </div>
              </div>
            </CardBox>
          </section>

          {page === "admin" && (
            <>
              <section className="mt-3 settings-table">
                {/* {adminStatus === "loading" && (
                <div className="text-center p-5">
                  <Spinner
                    animation="border"
                    className="ml-2 text-center text-success"
                    size="lg"
                  />
                </div>
              )} */}
                <CardBox>
                  <Table responsive hover size="md">
                    <thead className="settings-table-head">
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Status</th>
                        <th>Agency</th>
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {adminData?.users.map((user, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <img src={user.profile_picture} id="output" width="50px" height="50px" className="img-avatar" />
                            </td>
                            <td>
                              {user.first_name} {user.last_name}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.user_role}</td>
                            <td>{user.is_suspended ? <span className="badge badge-warning">suspended</span> : <span className="badge badge-success">active</span>}</td>
                            <td>{user.agency_id ? user?.agency.acronym : "no agency"}</td>
                            {/* {userform?.user_role === "superAdmin" && (
                              <td>
                                <button className="btn btn-sm btn-info mr-3" onClick={() => onAssign(user.email)}>
                                  <small>assign</small>
                                </button>
                              </td>
                            )} */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <button className="btn btn-sm btn btn-outline-secondary" onClick={viewMore}>
                    next
                  </button>
                </CardBox>
              </section>
            </>
          )}

          {page === "account" && (
            <section className="account-update">
              <CardBox>
                <form>
                  {form.error && <small className="text-danger mb-4">{form.message}</small>}
                  {!form.error && <small className="text-success mb-2">{form.message}</small>}

                  <div className="avatar-cont">
                    <img src={userform?.profile_picture} id="output" width="150px" height="150px" />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="first name"
                      value={userform.firstname}
                      onChange={(e) => setUserForm({ ...userform, firstname: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="last name"
                      value={userform.lastname}
                      onChange={(e) => setUserForm({ ...userform, lastname: e.target.value })}
                    />
                  </div>
                  {/* <div className="form-group mb-3">
                    <select
                      className="custom-select form-control form-control-sm "
                      id="inputGroupSelect01"
                      value={userform.admin}
                      onChange={(e) =>
                        setUserForm({ ...userform, admin: e.target.value })
                      }
                    >
                      <option selected>select roles</option>
                      <option value="staff">STAFF</option>
                  <option value="admin">ADMIN</option>
                  <option value="superAdmin">SUPER ADMIN</option>
                    </select>
                  </div> */}
                  <div className="form-group">
                    <input id="output" type="file" className="form-control" onChange={uploadMultipleFiles} multiple required />
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="phone number"
                      value={userform.phone}
                      onChange={(e) => setUserForm({ ...userform, phone: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="email" value={userform.email} onChange={(e) => setUserForm({ ...userform, email: e.target.value })} />
                  </div>

                  <button className="btn btn-success float-right">Update</button>
                </form>
              </CardBox>
            </section>
          )}

          <DialogBox
            open={openAssign}
            close={() => setOpenAssign(false)}
            buttonOk={
              <button className="btn btn-dark btn-sm" onClick={onAssignUser}>
                {assignLoading && <Spinner animation="border" className="ml-2" size="sm" />} assign
              </button>
            }
          >
            <form>
              {form.error && <small className="text-danger mb-4">{form.message}</small>}
              {!form.error && <small className="text-success mb-2">{form.message}</small>}
              <div className="form-group mb-3">
                <select className="custom-select form-control form-control-sm " id="inputGroupSelect01" value={form.role} onChange={(e) => setForm({ ...form, role: e.target.value })}>
                  <option selected>select roles</option>
                  <option value="staff">STAFF</option>
                  <option value="admin">ADMIN</option>
                  <option value="superAdmin">SUPER ADMIN</option>
                </select>
              </div>
            </form>
          </DialogBox>
        </div>
      </MainLayout>
    </>
  );
};

export default SettingsAgency;
