import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";


export const postPartner = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const fd = new FormData();
  fd.append("name", form.name);
  fd.append("image", form.image, "Image 14@2x.png");
  fd.append("category", form.category);

  const res = await Axios.post(API_CONSTANT_MAP.partner.create, fd, {
    headers
  });
  return res;
};

export const postEditPartner = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const fd = new FormData();
  fd.append("name", form.name);
  //fd.append("image", form.image, "Image 14@2x.png");
  fd.append("category", form.category);

  const res = await Axios.put(`${API_CONSTANT_MAP.partner.edit}${form.id}`, fd, {
    headers
  });
  return res;
};


export const fetchPartner = async ({
  params
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.partner.fetchAll}${params || 'ALL'}`, {
    headers
  });
  return res.data.data;
};

export const deletePartner = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
 
  const res = await Axios.delete(`${API_CONSTANT_MAP.partner.delete}${id}`, {
    headers
  });
  return res;
};