export const getThreshold = (zoom, latChangeRate, lngChangeRate) => {
  let threshold;
  if (zoom <= 22 && zoom >= 21) {
    threshold = latChangeRate > 0.02 || lngChangeRate > 0.14;
  } else if (zoom === 20) {
    threshold = latChangeRate > 0.06 || lngChangeRate > 0.2;
  } else if (zoom <= 19 && zoom >= 17) {
    threshold = latChangeRate > 0.11 || lngChangeRate > 0.4;
  } else if (zoom === 16) {
    threshold = latChangeRate > 1.4 || lngChangeRate > 5;
  } else if (zoom <= 15 && zoom >= 14) {
    threshold = latChangeRate > 2 || lngChangeRate > 6;
  } else if (zoom <= 13 && zoom >= 11) {
    threshold = latChangeRate > 23 || lngChangeRate > 35;
  } else if (zoom === 10) {
    threshold = lngChangeRate > 140;
  } else {
    threshold = false;
  }
  return threshold;
};
