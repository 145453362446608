import React, { Fragment, useState } from "react";
import "./styles.scss";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTES from "../../routes";
import auth2 from "../../images/auth2.png";
import { useMutation } from "react-query";
import { forgotPassword } from "../../queries/user";
import citi from "../../images/citi.svg";
import { NotificationContainer, NotificationManager } from "react-notifications";

const ForgotPassword = (props) => {
  const [mutate, { isLoading }] = useMutation(forgotPassword);
  const [form, setForm] = useState({
    email: "",
    message: "",
    error: false,
  });

  const onForgot = async (e) => {
    e.preventDefault();
    try {
      const res = await mutate({ form });
      const { data, hasError, errors, message } = res.data;
      if (hasError) {
        setForm({
          ...form,
          error: hasError,
          message: errors.message,
          email: "",
        });
        NotificationManager.error("Failed", errors.message);
      } else {
        setForm({
          ...form,
          error: hasError,
          message: message,
          email: "",
        });
        NotificationManager.success("Success", message);
      }
    } catch (error) {
      // Uh oh, something went wrong
      console.log(error);
    }
  };

  return (
    <Fragment>
      <main>
        <NotificationContainer />
        <section className="page-image">
          <div className="page-image-welcome">
            <p className="welcome-text">Welcome to Citi Monitor Think green, think clean, think Lagos.</p>
          </div>
        </section>
        <section className="page-form">
          <div className="page-form-container">
            <div className="page-form-fields">
              <img src={citi} alt="citi" width="70%" />
              <p className="auth-text mt-3">Enter your email and we'll send you a password reset link.</p>
              <Form onSubmit={onForgot}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Official email" className="auth-input" value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} required />
                </Form.Group>
                <div className="form-remember ml-0">
                  <Button variant="success" className="auth-button" type="submit">
                    {isLoading && <Spinner animation="border" className="ml-2" size="sm" />} Forgot
                  </Button>
                  <Link to={ROUTES.SIGNIN} className="mt-4">
                    Continue to Login
                  </Link>
                </div>
                {form.error ? <span className="text-danger ml-2">{form.message}</span> : <span className="text-success ml-2">{form.message}</span>}
              </Form>
            </div>
          </div>
          <div className="page-form-image-footer">
            <p className="auth-text">Powered by</p>
            <img src={auth2} width="70%" alt="lag" />
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default ForgotPassword;
