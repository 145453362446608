import React, { useState } from "react";
import "./styles.scss";
import { MainLayout } from "../../components";
import CardBox from "../../components/CardBox/CardBox";

import { fetchAgencyInfraction } from "../../queries/infractions";
import { usePaginatedQuery, useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import CardInfractionAgency from "../../components/CardInfractionAgency/CardInfractionAgency";
import { useHistory } from "react-router-dom";
import ViewMore from "../../components/ViewMore/ViewMore";

const InfractionsAgency = (props) => {
  const history = useHistory();
  const [page, setPage] = useState("all");
  const [pageCount, setPageCount] = useState(12);
  const { status, resolvedData} = usePaginatedQuery(
    ["infractionsAgency", page, pageCount],
    fetchAgencyInfraction
  );

  const viewMore = () => {
    setPageCount(pageCount + 6);
  }

  return (
    <>
      <MainLayout>
        <div className="infraction">
          <section className="infrac-nav">
            <CardBox>
              <div className="row">
                <div className="col-md-2">
                  <span onClick={() => {setPage("all")}} className={page === "all" ? "active-btn" : ''}>All </span>
                </div>
                <div className="col-md-2 ">
                  <span onClick={() => {setPage("ongoing")}} className={page === "ongoing" ? "active-btn" : ''}>Ongoing </span>
                </div>
                <div className="col-md-2">
                  <span onClick={() => {setPage("on hold")}} className={page === "on hold" ? "active-btn" : ''}>
                    OnHold{" "}
                  </span>
                </div>
                <div className="col-md-2">
                  <span onClick={() => {setPage("waiting")}} className={page === "waiting" ? "active-btn" : ''}>Waiting </span>
                </div>
                <div className="col-md-2">
                  <span onClick={() => {setPage("completed")}} className={page === "completed" ? "active-btn" : ''}>Completed </span>
                </div>
                <div className="col-md-1">
                  <span onClick={() => {setPage("due")}} className={page === "due" ? "active-btn" : ''}>Due</span>
                </div>
                <div className="col-md-1 text-right">
                  <span>
                    <i className="fa fa-filter" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </CardBox>
          </section>

          {status === "loading" && (
            <div className="text-center p-5">
              <Spinner
                animation="border"
                className="ml-2 text-center text-success"
                size="lg"
              />
            </div>
          )}

          {resolvedData?.agencyInfraction.length < 1 && (
            <div className="text-center p-5">
              <h6>No {page} infractions</h6>
            </div>
          )}

          <section className="infract-content mt-4">
            <div className="row">
              {resolvedData?.agencyInfraction.map((infrac, i) => {
                return (
                  <div className="col-md-4 mb-4">
                    <CardInfractionAgency
                      status={infrac.infraction.status}
                      key={i}
                      navigate={() =>
                        history.push(`/infraction/${infrac.infraction_id}`)
                      }
                      infraction={infrac.infraction}
                    />
                  </div>
                );
              })}
            </div>
            <ViewMore viewHandler={viewMore} />
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default InfractionsAgency;
