import React from 'react'
import './styles.scss'

const ViewMore = props => {
    const { viewHandler } = props
    return (
      <div className="view-more-container">
      <button className="btn btn-sm btn btn-outline-secondary" onClick={viewHandler}>
        view more
      </button>
    </div>
    )
}

export default ViewMore;