import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";



export const postAgency = async ({
  form
}) => {

  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const fd = new FormData();
  fd.append("name", form.name);
  fd.append("email", form.email);
  fd.append("about", form.about);
  fd.append("web_url", form.web_url);
  fd.append("image", form.image, "Image 14@2x.png");
  fd.append("category_ids", form.category_ids);
  fd.append("acronym", form.acronym);
 
  const res = await Axios.post(API_CONSTANT_MAP.agency, fd, {
    headers
  });
  return res;
};

export const postEditAgency = async ({
  form
}) => {

  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const fd = new FormData();
  fd.append("name", form.name);
  fd.append("email", form.email);
  fd.append("about", form.about);
  fd.append("web_url", form.web_url);
  fd.append("acronym", form.acronym);
 
  const res = await Axios.put(`${API_CONSTANT_MAP.agencyAdmin}/${form.id}`, fd, {
    headers
  });

  return res;
};


export const fetchAgency = async (key, count) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.agency}?pages=1&paginate=${count || 12}`, {
    headers
  });
  return res.data.data;
};

export const deleteAgency = async (
  id
) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.delete(`${API_CONSTANT_MAP.agency}/${id}`, {
    headers
  });
  return res;
};

export const fetchAgencyById = async ({
  id
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.agency}/${id}`, {
    headers
  });
  return res.data.data;;
};
