import React, { Suspense, useEffect, useState } from "react";
import { ReactQueryDevtools } from "react-query-devtools";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import ROUTES from "./routes";
import { Auth } from "./config/index";
import ScrollToTop from "./components/ScrollToTop";

//pages
import ErrorPage from "./pages/ErrorPage";
import Signin from "./pages/Signin/Signin";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import MobileRedirect from "./pages/Mobile_Redirect/MobileRedirect";
import Dashboard from "./pages/Dashboard";
import Infractions from "./pages/Infractions/Infractions";
import InfractionsMap from "./pages/InfractionsMap/InfractionsMap";
import Categories from "./pages/Categories/Categories";
import Agency from "./pages/Agency/Agency";
import News from "./pages/News/News";
import Sponsors from "./pages/Sponsors/Sponsors";
import Pioneer_Partners from "./pages/Pioneer_Partners/Pioneer_Partners";
import Infraction from "./pages/Infraction/Infraction";
import Lga from "./pages/Lga/Lga";
import Faq from "./pages/Faq/Faq";
import Settings from "./pages/Settings/Settings";
import CitiMonitor from "./pages/CitiMonitor";
import Events from "./pages/Events/Events";
import InfractionsAgency from "./pages/InfractionsAgency/InfractionsAgency";
import SettingsAgency from "./pages/SettingsAgency/SettingsAgency";
import DashboardAgency from "./pages/DashboardAgency/DashboardAgency";
import Search from "./pages/Search/Search";
import AuditLogs from "./pages/Audit_Logs/Audit_Logs";
import Market from "./pages/Market/Market";
import UserProfile from "./pages/UserProfile/UserProfile";

//const Signin = React.lazy(()=> import('./pages/Signin/Signin'));

//const user = Auth.getUser();

const App = (props) => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Switch>
        <Route exact path={ROUTES.SIGNIN} component={Signin} />
        <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <RestictedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route exact path={ROUTES.MOBILE_REDIRECT} component={MobileRedirect} />
        <RestictedRoute exact path={ROUTES.SEARCH} component={Search} />
        <RestictedRoute exact path={ROUTES.INFRACTIONS} component={Infractions} />
        <RestictedRoute exact path={ROUTES.INFRACTIONS_MAP} component={InfractionsMap} />
        <RestictedRoute exact path={ROUTES.INFRACTION} component={Infraction} />
        <RestictedRoute exact path={ROUTES.CATEGORIES} component={Categories} />
        <RestictedRoute exact path={ROUTES.AGENCIES} component={Agency} />
        <RestictedRoute exact path={ROUTES.NEWS} component={News} />
        <RestictedRoute exact path={ROUTES.SPONSORS} component={Sponsors} />
        <RestictedRoute exact path={ROUTES.PIONEER_PARTNERS} component={Pioneer_Partners} />
        <RestictedRoute exact path={ROUTES.LGA} component={Lga} />
        <RestictedRoute exact path={ROUTES.FAQ} component={Faq} />
        <RestictedRoute exact path={ROUTES.SETTINGS} component={Settings} />
        <RestictedRoute exact path={ROUTES.AUDIT_LOGS} component={AuditLogs} />
        <RestictedRoute exact path={ROUTES.USER_ACTIVITY} component={UserProfile} />
        <RestictedRoute exact path={ROUTES.EVENTS} component={Events} />
        <RestictedRoute exact path={ROUTES.MARKET} component={Market} />

        <RestictedRouteAgency exact path={ROUTES.DASHBOARD_AGENCY} component={DashboardAgency} />
        <RestictedRouteAgency
          exact
          path={ROUTES.INFRACTIONS_AGENCY}
          component={InfractionsAgency}
        />
        <RestictedRouteAgency exact path={ROUTES.SETTINGS_AGENCY} component={SettingsAgency} />

        <Route exact path={ROUTES.ERROR_PAGE} component={ErrorPage} />
        <Suspense fallback={CitiMonitor}></Suspense>
      </Switch>
      <ReactQueryDevtools initialIsOpen={false} />
    </BrowserRouter>
  );
};

const RestictedRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(Auth.getUser());
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect
              to={{
                pathname: "/",
              }}
            />
            {Auth.destroyToken()}
          </>
        )
      }
    />
  );
};
//&& user.agency.acronym === 'MOEWR' && user.user_role === 'admin'
const RestictedRouteAgency = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(Auth.getUser());
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect
              to={{
                pathname: "/",
              }}
            />
            {Auth.destroyToken()}
          </>
        )
      }
    />
  );
};

export default App;
