import React, { Fragment, useState } from "react";
import "./styles.scss";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import ROUTES from "../../routes";
import auth2 from "../../images/auth2.png";
import google_play_badge from "../../images/google-play-badge.png";
import apple_app_store_badge from "../../images/apple-app-store-badge.png";
import citi from "../../images/citi.svg";
import { NotificationContainer, NotificationManager } from "react-notifications";

const MobileRedirect = (props) => {
  return (
    <Fragment>
      <NotificationContainer />
      <main>
        <section className="page-image">
          <div className="page-image-welcome">
            <p className="welcome-text">Welcome to Citi Monitor Think green, think clean, think Lagos.</p>
          </div>
        </section>
        <section className="page-form">
          <div className="page-form-container">
            <div className="page-form-fields">
              <img src={citi} alt="citi" width="70%" />
              <p className="auth-text mt-3">Hello! Log in on the mobile app to access your account.</p>

              <section className="store-badges">
                <img src={google_play_badge} alt="lag" />
                <img src={apple_app_store_badge} alt="lag" />
              </section>
              <div className="page-form-image-footer">
                <p className="auth-text">Powered by</p>
                <img src={auth2} width="70%" alt="lag" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default MobileRedirect;
