import Axios from "axios";
import API_CONSTANT_MAP from "../services/uri";
import {
  Auth
} from "../config";


export const postAdvert = async ({
  form
}) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const fd = new FormData();
  fd.append("url", form.url);
  fd.append("image", form.image, "Image 14@2x.png");
 
  const res = await Axios.post(API_CONSTANT_MAP.advert, fd, {
    headers
  });
  return res;
};


export const fetchAdvert = async () => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const res = await Axios.get(`${API_CONSTANT_MAP.advert}`, {
    headers
  });
  return res.data.data;
};

export const deleteAdvert = async (id) => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
 
  const res = await Axios.delete(`${API_CONSTANT_MAP.advert}/${id}`, {
    headers
  });
  return res;
};